<template>
  <div>
    <v-card>
      <v-container fluid>
        <v-row class="pl-0">

          <v-col cols="12" sm="3" class="border px-0 py-0">
            <div class="pr-3">

            <!-- <v-expansion-panels v-model="panelChannel" multiple class="pb-3">
              <v-expansion-panel v-if="this.channelInfo != null">
                <v-expansion-panel-header>
                  <v-avatar>
                    <v-list-item-avatar>
                      <v-img
                        v-if="this.channelInfo.channelPictureURL != null && this.channelInfo.channelPictureURL != ''"
                        :src="this.channelInfo.channelPictureURL"
                      ></v-img>
                      <v-img
                        v-else
                        src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-avatar>
                  <h3>
                    {{this.channelInfo.channelName}}
                  </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list shaped>
                    <v-list-item-group>
                      <v-list-item
                        v-for="item in channelList"
                        :key="item.length"
                        class="mx-n6"
                        @change="selectChannel(item)"
                      >
                        <v-badge
                          bordered
                          bottom
                          color="green"
                          dot
                          offset-x="22"
                          offset-y="26"
                        >
                          <v-list-item-avatar>
                            <v-img
                              v-if="item.channelPictureURL != null && item.channelPictureURL != ''"
                              :src="item.channelPictureURL"
                            />
                            <v-img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg"></v-img>
                          </v-list-item-avatar>
                        </v-badge>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.channelName
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->
              <v-select
                v-model="selectChannelItem"
                :items="channelList"
                item-text="platformShopName"
                item-value="id"
                persistent-hint
                hide-details
                outlined
              >
                <template slot="selection" slot-scope="data">
                  <img
                    v-if="data.item.platformID == 0"
                    style="width: 40px"
                  />
                  <img
                    v-else-if="data.item.platformShopProfilePicture != null && data.item.platformShopProfilePicture != ''"
                    style="width: 40px"
                    :src="data.item.platformShopProfilePicture"
                  />
                  <img
                    v-else
                    style="width: 40px"
                    :src="data.item.platformLogo"
                  />
                  <v-avatar v-if="data.item.platformShopProfilePicture != null && data.item.platformShopProfilePicture != ''" size="16" class="ml-n3 mt-n5">
                    <v-img
                      v-if="data.item.platformLogo != null && data.item.platformLogo != ''"
                      :src="data.item.platformLogo"
                    />
                    <v-img
                      v-else
                      src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg">
                    </v-img>
                  </v-avatar>
                    <span class="ml-3">
                      {{data.item.platformShopName == "" ? (data.item.platformName + '.' + data.item.id ) :  data.item.platformShopName}}
                    </span>
                </template>

                <template slot="item" slot-scope="data">
                  <div @click="selectChannel(data.item)" class="d-flex" style="width:100%">
                    <img
                      v-if="data.item.platformID == 0"
                    />
                    <img
                      v-else-if="data.item.platformShopProfilePicture != null && data.item.platformShopProfilePicture != ''"
                      class="title-select"
                      :src="data.item.platformShopProfilePicture"
                    />
                    <img
                      v-else
                      class="title-select"
                      :src="data.item.platformLogo"
                    />
                    <v-avatar v-if="data.item.platformShopProfilePicture != null && data.item.platformShopProfilePicture != ''" size="15" class="ml-n3">
                      <v-img
                        v-if="data.item.platformLogo != null && data.item.platformLogo != ''"
                        :src="data.item.platformLogo"
                      />
                      <v-img
                        v-else
                        src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg">
                      </v-img>
                    </v-avatar>
                    <div class="ml-3 mt-1">
                      {{data.item.platformShopName == "" ? (data.item.platformName + '.' + data.item.id ) :  data.item.platformShopName}}
                    </div>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="separator mt-3 mb-3"></div>

            <!-- <v-app-bar flat color="rgba(0,0,0,0)">
              <v-toolbar-title class="title"> Chat </v-toolbar-title>

              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </v-app-bar>

            <v-app-bar flat color="rgba(0,0,0,0)">
              <v-text-field
                label="Search Here"
                append-icon="mdi-magnify"
                color="grey"
              ></v-text-field>
            </v-app-bar>

            <div class="text-right">
              <v-btn @click="getChatMessageTitle()" icon>
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </div>

            <div @scroll="handleScroll" class="scroll-container">
              <div v-for="item in items" :key="item.id" class="item">
                {{ item.name }}
              </div>
            </div> -->

            <v-list two-line color="rgba(0,0,0,0)" @scroll="handleScrollTitle" class="scroll-container py-0" :style="{ height: (window.height-229) + 'px' }">
              <v-list-item-group
                :value="selected"
                active-class="blue lighten-4"
                :mandatory="activeChat"
              >
                <template v-for="item in channelTitleList">
                  <v-list-item
                    :key="item.chatID"
                    :value="item"
                    @click="getChatMessageDetail(item, activeChat = true)"
                    :class="{ 'active-item': selected && selected.chatID === item.chatID }"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.platformSenderProfilePicture != '' && item.platformSenderProfilePicture != null"
                        :src="item.platformSenderProfilePicture">
                      </v-img>
                      <v-img
                        v-else
                        src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAyL3BmLWljb240LWppcjIwNjQtcG9yLTAzLWxjb3B5LnBuZw.png">
                      </v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar size="17" class="ml-n7 mt-n1 mr-3">
                      <v-img
                        v-if="item.platformLogo != null && item.platformLogo != ''"
                        :src="item.platformLogo"
                      />
                      <!-- <v-img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg"/> -->
                    </v-list-item-avatar>
                    <v-list-item-avatar size="17" class="ml-n7 mb-n1 mr-3">
                      <v-img
                        v-if="item.platformShopProfilePicture != null && item.platformShopProfilePicture != ''"
                        :src="item.platformShopProfilePicture"
                      />
                      <!-- <v-img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg"/> -->
                    </v-list-item-avatar>
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-if="item.platformSenderName != null && item.platformSenderName != ''">
                          {{item.platformSenderName}}
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          {{item.platformName + '.' + item.chatID}}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{item.lastMessage}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <div class="text-right">
                        <v-badge
                          v-if="item.unreadCount > 0"
                          color="info"
                          :content="item.unreadCount"
                          inline
                        />
                        <div v-else class="mb-6"></div>
                        <v-list-item-subtitle style="font-size: 10px;" class="mt-1 mb-1">
                          {{item.lastMessageDate}}
                        </v-list-item-subtitle>
                      </div>
                    </template>
                  </v-list-item>
                  <v-divider :key="item.length"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" sm="5" class="border px-0 py-0">
            <div class="border-head " style="height:69px">
              <table style="width:100%">
                <tr>
                  <td class="pa-2" style="width:50px;">
                    <v-avatar v-if="this.messageInfoOfTitle.length != 0" size="40" elevation="10">
                      <img
                        v-if="this.messageInfoOfTitle.platformSenderProfilePicture != '' && this.messageInfoOfTitle.platformSenderProfilePicture != null"
                        :src="this.messageInfoOfTitle.platformSenderProfilePicture"
                      />
                      <img v-else src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAyL3BmLWljb240LWppcjIwNjQtcG9yLTAzLWxjb3B5LnBuZw.png"/>
                    </v-avatar>
                  </td>
                  <td>
                    <div v-if="this.messageInfoOfTitle.length != 0" class="title pl-0">
                      <v-toolbar-title v-if="this.messageInfoOfTitle.platformSenderName != ''">
                        {{this.messageInfoOfTitle.platformSenderName}}
                      </v-toolbar-title>
                      <v-toolbar-title v-else>
                        {{this.platformName + '.' + this.chatIdForSendMessage}}
                      </v-toolbar-title>
                      <div class="mt-n2 platform-head ">
                        ช่องทาง : {{this.platformName}}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

                <v-spacer></v-spacer>
                <!-- <v-toolbar-title class="title pl-0 mr-2 mt-n4">
                  Members :
                </v-toolbar-title>
                <v-btn
                  color="blue"
                  icon
                  class="mt-n5 mr-n2"
                  outlined
                  max-height="35"
                  max-width="35"
                >
                  <v-icon small>fas fa-plus</v-icon>
                </v-btn>
                <v-avatar class="mt-n5 mr-2" size="30" elevation="10">
                  <img src="https://cdn.vuetifyjs.com/images/lists/5.jpg" />
                </v-avatar>
                <v-divider vertical inset class="mt-n1"></v-divider>
                <v-btn color="black" icon class="mt-n5">
                  <v-icon>mdi-cog</v-icon>
                </v-btn> -->

            </div>
            <ul style="border-bottom: 1px solid #dcdee2;" ref="scrollContainer" class="scroll-container" :style="{ height: (window.height-321) + 'px' }" @scroll="handleScrollMessage">
              <div v-for="item in messageInfo" :key="item.length" class="px-0 container-chat">

                <div v-if="item.sent_by_admin == true" class="right-content">
                  <div class="pr-4">
                    <div>
                      <v-card v-if="item.messageType == 'text'" class="pa-3" max-width="350px" color="blue" dark>
                        <!-- <div v-if="item.message.includes('http')">
                          <p v-html="convertTextToUrl(item.message)"></p>
                        </div>
                        <div v-else>{{item.message}}</div> -->
                        <p v-html="convertTextToUrl(item.message, item.sent_by_admin)" class="mb-0"></p>
                      </v-card>

                      <div v-if="item.messageType == 'image'">
                        <div v-if="platformId == 2">
                          <a @click="viewImage(item.message, 'image')"><v-icon>mdi-image</v-icon>ดูรูปภาพ</a>
                        </div>
                        <v-img
                          v-else
                          :src="item.message"
                          width="200"
                          @click="viewPhoto(item.message)"
                          @load="onContentLoad"
                        />
                      </div>
                    </div>
                    <!-- <v-avatar class="ml-2" size="30" elevation="10">
                      <img
                        v-if="channelProfilePic != null && channelProfilePic != ''"
                        :src="channelProfilePic"
                      />
                      <img v-else src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/OasysLOGO.jpg" />
                    </v-avatar> -->
                  </div>
                </div>
                <div v-if="item.sent_by_admin == true" class="mb-5 right-content col-12 pa-0">
                  <div class="mt-2 mr-5 text-right time-send">
                    {{item.sent_time + " " + item.sent_by_staffName}}
                  </div>
                </div>

                <div v-else class="mb-5">
                  <div class="d-flex">
                    <v-avatar class="mr-2" size="30" elevation="10">
                      <img
                        v-if="messageInfoOfTitle.platformSenderProfilePicture != '' && messageInfoOfTitle.platformSenderProfilePicture != null"
                        :src="messageInfoOfTitle.platformSenderProfilePicture"
                      />
                      <img v-else src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAyL3BmLWljb240LWppcjIwNjQtcG9yLTAzLWxjb3B5LnBuZw.png"/>
                    </v-avatar>
                    <div>
                      <v-card v-if="item.messageType == 'text'" class="pa-3" max-width="350px">
                        <!-- <div>{{item.message}}</div> -->
                        <p v-html="convertTextToUrl(item.message, item.sent_by_admin)" class="mb-0"></p>
                      </v-card>
                      <div v-if="item.messageType == 'image'">
                        <!-- <v-img
                          v-if="platformId == 2"
                          :src="'data:image/jpeg;base64,'+item.message"
                          width="200"
                          @click="viewPhoto('data:image/jpeg;base64,'+item.message)"
                          @load="onContentLoad"
                          alt="Image"
                        /> -->
                        <div v-if="platformId == 2">
                          <div v-if="item.loadImage == true">
                            <v-img
                              :src="item.message"
                              width="200"
                              @click="viewPhoto(item.message)"
                              @load="onContentLoad"
                              alt="Image"
                            />
                          </div>
                          <div v-else>
                            <v-progress-circular
                              indeterminate
                              color="red darken-2"
                            />
                          </div>
                        </div>
                        <v-img
                          v-else
                          :src="item.message"
                          width="200"
                          @click="viewPhoto(item.message)"
                          @load="onContentLoad"
                          alt="Image"
                        />
                      </div>
                      <div v-if="item.messageType == 'video'">
                        <!-- <video width="200" controls v-if="platformId == 2" @loadedmetadata="onContentLoad">
                          <source :src="'data:image/jpeg;base64,'+item.message" type="video/mp4">
                        </video> -->
                        <v-card v-if="platformId == 2" class="container-play" width="200px" height="100px" color="grey">
                          <div class="center-play">
                            <v-btn v-if="loadVideo == false" icon dark @click="viewVideo(item.message, 'video')">
                              <v-icon size="50">mdi-play-circle-outline</v-icon>
                            </v-btn>
                            <v-progress-circular
                              v-else
                              indeterminate
                              color="red darken-2"
                            />
                          </div>
                        </v-card>
                        <video width="200" controls v-else @loadedmetadata="onContentLoad">
                          <source :src="item.message" type="video/mp4">
                        </video>
                      </div>
                    </div>
                    <v-btn @click="downloadImage(item.message)" v-if="item.messageType == 'image' && platformId != 2" color="grey" icon>
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                  <div class="mt-2 ml-10 time-send">
                    {{item.sent_time + ", read by : " + item.read_by_staffname}}
                  </div>
                </div>

              </div>
            </ul>

            <v-app-bar color="rgba(0,0,0,0)" flat class="mt-8">
              <v-text-field
                :value="messageForSendMessage"
                @change="value => messageForSendMessage = value"
                append-outer-icon="mdi-send"
                filled
                clearable
                label="Message"
                type="text"
                @click:append-outer="sendMessageToPlatform"
                @keyup.enter="sendMessageToPlatform"
                :disabled='messageInfo.length == 0'
              ></v-text-field>
            </v-app-bar>

            <v-dialog v-model="showPhotoChat" max-width="700">
              <v-card class="ma-0">
                <v-card-text>
                  <v-row align="center">
                    <v-col v-if="loadImage == true" cols="12" class="text-center">
                      <v-progress-circular
                        indeterminate
                        color="red darken-2"
                      />
                    </v-col>
                    <v-col v-else cols="12" class="text-center">
                      <img class="hidden-md-and-up" :src="showPhotoObj" width="270" />
                      <img
                        class="hidden-sm-and-down"
                        :src="showPhotoObj"
                        width="550"
                      />
                      <div>
                        <a @click="downloadImage(showPhotoObj)">ดาวน์โหลดรูปภาพ</a>
                      </div>
                      <!-- <v-btn @click="downloadImage()" color="grey" icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn> -->
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn text color="#1976d2" @click="showPhotoChat = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showVideoChat" max-width="500">
              <v-card class="ma-0">
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12" class="text-center">
                      <video width="400" controls>
                        <source :src="showVideoObj" type="video/mp4">
                      </video>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-btn text color="#1976d2" @click="showVideoChat = false, loadVideo = false">
                    ปิด
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="4" class="pr-0">
            <v-tabs v-model="tab">
              <v-tabs-slider color="red"></v-tabs-slider>
              <v-tab v-for="item in tab_items" :key="item"> {{ item }} </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
              <v-tab-item v-for="item in tab_items" :key="item">
                <div v-if="item == 'แท็ก'">
                   
                </div>

                <!-- add order -->
                <div v-if="item == 'สร้างออร์เดอร์' && ( platformId == 1 || platformId == 2 )">
                  <div v-if="chatIdForSendMessage == 0">
                   
                  </div>
                  <div v-else class="scroll-container-col3" :style="{ height: (window.height-220) + 'px' }">
                    <div v-if="page_loading">
                      <loading :page_loading="page_loading" />
                    </div>
                    <div v-else>
                      <loading :loading="loading" />

                      <!-- Add products -->
                      <v-card class="v-card-bottom-30">
                        <v-card-text class="px-0">
                          <!-- เลือกคลังก่อน เพิ่มสินค้า -->
                          <v-row class="py-2">
                            <v-col cols="12" class="py-0">
                              <!-- {{ Warehouse }} -->
                              <v-select
                                v-if="productList.length != 0"
                                v-model="Warehouse"
                                :items="Warehouse_List"
                                item-text="name"
                                item-value="id"
                                persistent-hint
                                return-object
                                dense
                                hide-details
                                disabled
                                outlined
                                single-line
                                @change="update_warehouseID()"
                              ></v-select>
                              <v-select
                                v-else
                                v-model="Warehouse"
                                :items="Warehouse_List"
                                item-text="name"
                                item-value="id"
                                persistent-hint
                                return-object
                                dense
                                hide-details
                                outlined
                                single-line
                                @change="update_warehouseID()"
                              ></v-select>
                            </v-col>
                            <v-col class="py-0" />
                          </v-row>
                          <v-row>
                            <v-col class="py-2">
                              <v-autocomplete
                                v-model="searchStockModel"
                                ref="searchStockModel"
                                :hide-no-data="hideNoDataSearchStock"
                                :items="data_tmp.StockList"
                                append-icon="mdi-magnify"
                                clearable
                                dense
                                hide-details
                                item-text="stockNameSearch"
                                no-data-text="ไม่พบสินค้า"
                                outlined
                                return-object
                                @blur="(hideNoDataSearchStock = true), (data_tmp.StockList = [])"
                                @keyup.enter="searchStock()"
                              >
                                <template v-slot:label>
                                  <span>{{ use_language.name_product }}</span>
                                </template>

                                <template v-slot:item="{ item }">
                                  <v-list-item-avatar>
                                    <v-img
                                      :src="item.photoLink == null ? '' : item.photoLink"
                                    ></v-img>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text=" item.sku + ' : ' + item.stockFullname + ', ' + use_language.remaining + ' ' + item.quantityAvailable + ' ' +  use_language.item"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-text="item.WarehouseName"
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col  class="py-2">
                              <div >
                                <v-btn
                                  :loading="searchStockLoading"
                                  color="primary"
                                  class="mr-1"
                                  outlined
                                  @click="OpenPopup(limits, 0)"
                                >
                                  <v-icon center>mdi-plus</v-icon>
                                  {{ use_language.select_product }}
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="pb-0">
                              <v-data-table
                                :headers="[
                                  {
                                    text: '#',
                                    value: 'index',
                                    align: 'center',
                                    sortable: false,
                                    width: 10,
                                  },
                                  {
                                    text: use_language.picture,
                                    value: 'photoLink',
                                    align: 'center',
                                    sortable: false,
                                    width: 10,
                                  },
                                  {
                                    text: use_language.name_product,
                                    value: 'stockFullname',
                                    align: 'left',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.selling_price,
                                    value: 'salePrice',
                                    align: 'center',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.quantity,
                                    value: 'quantityInput',
                                    align: 'center',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.discount_percent,
                                    value: 'discountPercent',
                                    align: 'center',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.discount,
                                    value: 'discount',
                                    align: 'center',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.total_price,
                                    value: 'itemPrice',
                                    align: 'right',
                                    sortable: false,
                                    width: 100,
                                  },
                                  {
                                    text: use_language.delete,
                                    value: 'delete',
                                    align: 'center',
                                    sortable: false,
                                    width: 10,
                                  },
                                ]"
                                :items="productList"
                                :items-per-page="10000"
                                hide-default-footer
                                class="packhai-border-table packhai-table"
                              >
                                <!-- ใช้แทน no-data-text="กรุณาเลือกสินค้า" -->
                                <template v-slot:no-data>
                                  <span>{{ use_language.please_select_product }}</span>
                                </template>

                                <template v-slot:item.index="{ item }">
                                  {{ productList.indexOf(item) + 1 }}
                                </template>
                                <template v-slot:item.name="{ item }">
                                  {{ item.stockFullName }}
                                </template>
                                <template v-slot:item.photoLink="{ item }">
                                  <v-img
                                    :src="item.photoLink == null ? '' : item.photoLink"
                                    width="50"
                                    height="50"
                                    class="ma-1"
                                  ></v-img>
                                </template>
                                <template v-slot:item.salePrice="{ item }">
                                  <v-text-field
                                    :value="item.salePrice"
                                    @change="(value) => (item.salePrice = value)"
                                    autocomplete="off"
                                    dense
                                    hide-details
                                    outlined
                                    ref="untiPriceInput"
                                    type="number"
                                    @blur="unitPriceInput(item)"
                                  ></v-text-field>
                                </template>
                                <template v-slot:item.quantityInput="{ item }">
                                  <v-text-field
                                    :value="item.quantityInput"
                                    @change="(value) => (item.quantityInput = value)"
                                    autocomplete="off"
                                    dense
                                    hide-details
                                    outlined
                                    ref="quantityInput"
                                    type="number"
                                    @blur="quantityInput(item)"
                                  ></v-text-field>
                                  <span
                                    v-if="quantityInput_error != null"
                                    class="my-1"
                                    style="color: red"
                                    >{{ quantityInput_error }}</span
                                  >
                                </template>
                                <template v-slot:item.discountPercent="{ item }">
                                  <v-text-field
                                    v-model="item.discountPercent"
                                    autocomplete="off"
                                    :disabled="
                                      item.quantityInput == '' || item.quantityInput == null
                                    "
                                    dense
                                    hide-details
                                    outlined
                                    type="number"
                                    @input="calculateDiscountPercent(item)"
                                    @change="calculateTotalStockPrice()"
                                  ></v-text-field>
                                </template>
                                <template v-slot:item.discount="{ item }">
                                  <v-text-field
                                    v-model="item.discount"
                                    autocomplete="off"
                                    :disabled="
                                      (item.discountPercent != '' &&
                                        item.discountPercent != null) ||
                                      item.quantityInput == '' ||
                                      item.quantityInput == null
                                    "
                                    dense
                                    hide-details
                                    outlined
                                    type="number"
                                    @change="calculateTotalStockPrice()"
                                  ></v-text-field>
                                </template>

                                <template v-slot:item.itemPrice="{ item }">
                                  <span>{{ formatMoney(item.itemPrice.toFixed(2)) }}</span>
                                </template>
                                <template v-slot:item.delete="{ item }">
                                  <v-btn icon color="red" @click="removeProduct(item)"
                                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                                  >
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                          <v-row justify="end">
                            <v-col>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                <v-col>
                                  {{ use_language.total_product_value }}
                                </v-col>
                                <v-col cols="5" align="right">
                                  <span>{{ formatMoney(ProductPrice.toFixed(2)) }}</span>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                <v-col>
                                  {{ use_language.shipping_cost }}
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field
                                    :value="textField_deliveryCost"
                                    @change="(value) => (textField_deliveryCost = value)"
                                    autocomplete="off"
                                    dense
                                    hide-details
                                    outlined
                                    type="number"
                                    @blur="calculateTotalStockPrice()"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="justify-space-between py-3">
                                <v-col cols="6" class="d-flex">
                                  {{ use_language.discount }}
                                  <v-select
                                    v-model="select_discountType"
                                    :items="['฿', '%']"
                                    dense
                                    hide-details
                                    outlined
                                    class="pl-2"
                                    @change="calculateTotalStockPrice()"
                                  ></v-select>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field
                                    :value="textField_discount"
                                    @change="(value) => (textField_discount = value)"
                                    autocomplete="off"
                                    dense
                                    hide-details
                                    outlined
                                    type="number"
                                    @blur="calculateTotalStockPrice()"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                <v-col>
                                  {{ use_language.vat }}
                                </v-col>
                                <v-col align="end">
                                  {{ format_price(totalVat) }}
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row class="py-3">
                                <v-col>
                                  <h4>{{ use_language.total }}</h4>
                                </v-col>
                                <v-col align="end">
                                  <div>{{ totalPrice }}</div>
                                  <h4>
                                    {{
                                      format_price(
                                        totalPrice +
                                          Number(textField_deliveryCost) -
                                          totalDiscount +
                                          (select_vatType == 3 ? totalVat : 0)
                                      )
                                    }}
                                  </h4>
                                </v-col>
                              </v-row>
                              <v-divider></v-divider>
                              <v-row>
                                <v-col>
                                  <v-select
                                    v-model="select_vatType"
                                    :items="[
                                      { text: use_language.no_vat, value: 1 },
                                      { text: use_language.price_including_tax, value: 2 },
                                      { text: use_language.price_not_include_tax, value: 3 },
                                    ]"
                                    dense
                                    hide-details
                                    item-text="text"
                                    item-value="value"
                                    outlined
                                    @change="calculateTotalStockPrice()"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-textarea
                                    :value="textarea_remarkProduct"
                                    dense
                                    hide-details
                                    outlined
                                    rows="2"
                                    @change="(value) => (textarea_remarkProduct = value)"
                                  >
                                    <!-- ใช้แทน placeholder -->
                                    <template v-slot:label>
                                      <span>{{ use_language.note }}</span>
                                    </template>
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <!-- End add products -->

                      <v-form ref="form" lazy-validation>

                        <!-- รูปแบบพัสดุ -->
                        <v-card class="v-card-bottom-30">
                          <v-card-title class="px-0">
                            <h4>{{ use_language.parcel_format }}</h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <v-row>
                              <v-col cols="12" class="pb-0">
                                <h4>{{ use_language.category }} :</h4>
                              </v-col>
                              <v-col cols="12" class="pb-0">
                                <v-radio-group
                                  @change="
                                    rd_is_cod_changed(), rd_bank_changed(select_bank_exist)
                                  "
                                  v-model="orderMaster.isCOD"
                                  ref="rd_is_cod"
                                  row
                                  required
                                  :rules="rd_is_cod_rule"
                                  class="mb-2 is-cod-selector"
                                >
                                  <v-radio label="COD" :value="true">
                                    <template v-slot:label>
                                      <span>{{ use_language.cod }}</span>
                                    </template>
                                  </v-radio>
                                  <v-radio label="ชำระเงินแล้ว" :value="false">
                                    <template v-slot:label>
                                      <span>{{ use_language.paid }}</span>
                                    </template>
                                  </v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <div v-if="orderMaster.isCOD !== null && !orderMaster.isCOD">
                              <v-row>
                                <v-col cols="4">
                                  <font class="font-weight-bold"
                                    >{{ use_language.total_ }} :</font
                                  >
                                </v-col>
                                <v-col cols="8">
                                  {{
                                    format_price(
                                      totalPrice +
                                        Number(textField_deliveryCost) -
                                        totalDiscount +
                                        (select_vatType == 3 ? totalVat : 0)
                                    )
                                  }}
                                  บาท
                                </v-col>
                              </v-row>
                            </div>
                            <div v-else-if="orderMaster.isCOD">
                              <v-row>
                                <v-col cols="12" class="pb-0">
                                  <h4>{{ use_language.total_cod }}:</h4>
                                </v-col>
                                <v-col cols="12" class="pb-0">
                                  <v-text-field
                                    v-model="orderMaster.codamount"
                                    autocomplete="off"
                                    type="number"
                                    hide-details="auto"
                                    dense
                                    outlined
                                    :rules="input_cod_amount_rules"
                                    required
                                    @change="set_cod_amount_format()"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" class="pb-0">
                                  <h4>{{ use_language.account }} :</h4>
                                </v-col>
                                <v-col cols="12" class="pb-0">
                                  <v-radio-group
                                    v-model="rd_bank"
                                    row
                                    @change="rd_bank_changed(select_bank_exist)"
                                  >
                                    <v-radio label="บัญชีที่มีอยู่" value="bank_exist">
                                      <template v-slot:label>
                                        <span>{{ use_language.existing_account }}</span>
                                      </template>
                                    </v-radio>
                                    <v-radio
                                      v-if="permission_manage_bank_account == '1'"
                                      label="บัญชีอื่น"
                                      value="bank_other"
                                    >
                                      <template v-slot:label>
                                        <span>{{ use_language.another_account }}</span>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </v-col>
                              </v-row>
                              <div v-if="rd_bank == 'bank_exist'">
                                <v-row>
                                  <v-col cols="12">
                                    <h4>{{ use_language.account }} :</h4>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-select
                                      v-model="select_bank_exist"
                                      @change="rd_bank_changed(select_bank_exist)"
                                      :items="bank_exist_list"
                                      item-text="fullBankName"
                                      item-value="id"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="select_bank_exist_rules"
                                      required
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </div>
                              <div v-else-if="rd_bank == 'bank_other'">
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <h4>{{ use_language.bank }} :</h4>
                                  </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-select
                                      @change="new_bank_changed()"
                                      v-model="select_bank"
                                      :items="bank_list"
                                      item-text="text"
                                      item-value="id"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="select_bank_rules"
                                      required
                                    ></v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <h4>{{ use_language.name_account }} :</h4>
                                  </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-text-field
                                      :value="input_account_name"
                                      @change="(value) => (input_account_name = value)"
                                      @blur="new_bank_changed()"
                                      autocomplete="off"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="input_account_name_rules"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <h4>{{ use_language.bank_number }} :</h4>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      :value="input_account_number"
                                      @change="(value) => (input_account_number = value)"
                                      @blur="new_bank_changed()"
                                      autocomplete="off"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="input_account_number_rules"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>

                        <!-- แจ้งชำระเงิน -->
                        <v-card
                          v-if="!orderMaster.isCOD && orderMaster.isCOD != null"
                          class="v-card-bottom-30"
                        >
                          <v-card-title class="px-0">
                            <h4>{{ use_language.payment_notification }}</h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <v-btn outlined color="success" @click="bt_addTransfer()">
                              <v-icon>mdi-plus</v-icon>{{ use_language.add_payment_notification }}
                            </v-btn>
                            <v-dialog v-model="dialog_transfer" persistent max-width="1500">
                              <v-card>
                                <v-card-title>
                                  {{ use_language.transfer_money }}
                                  <v-spacer />
                                  <v-btn icon @click="bt_closeTransfer()">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                  <v-form ref="dialogTransferForm">
                                    <v-row>
                                      <v-col cols="12" class="pb-0">
                                        <h4>{{ use_language.in_account }} :</h4>
                                      </v-col>
                                      <v-col cols="12" class="pb-0">
                                        <v-radio-group
                                          v-model="rdGroup_transferBankAccount"
                                          :rules="[(v) => !!v || 'กรุณาระบุ']"
                                          required
                                          class="mb-2"
                                        >
                                          <v-radio
                                            v-for="(item, index) in bank_exist_list2"
                                            :key="item.id"
                                            :label="item.fullBankName"
                                            :value="bank_exist_list2[index]"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                  </v-form>
                                  <v-row>
                                    <v-col cols="12" class="pb-0">
                                      <h4>{{ use_language.attach_slip }} :</h4>
                                    </v-col>
                                    <v-col cols="12" class="pb-0">
                                      <v-file-input
                                        v-model="input_transferFileUpload"
                                        ref="input_transferFileUpload"
                                        outlined
                                        hide-details="auto"
                                        accept="image/png, image/jpeg"
                                        dense
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-file-image"
                                        @change="check_type_file()"
                                      >
                                        <template v-slot:selection="{ text }">
                                          <v-chip small label color="primary">
                                            {{ text }}
                                          </v-chip>
                                        </template>
                                      </v-file-input>
                                      <span
                                        v-if="
                                          input_transferFileUpload != null &&
                                          input_transferFileUpload_valid == true
                                        "
                                        style="color: red"
                                        >{{ use_language.choose_only_jpeg_png }}</span
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" class="pb-0">
                                      <h4>{{ use_language.transfer_amount }} :</h4>
                                    </v-col>
                                    <v-col cols="12" class="pb-0">
                                      <v-text-field
                                        :value="input_transferAmount"
                                        @change="(value) => (input_transferAmount = value)"
                                        autocomplete="off"
                                        type="number"
                                        outlined
                                        hide-details
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" class="pb-0">
                                      <h4>{{ use_language.transfer_date }} :</h4>
                                    </v-col>
                                    <v-col cols="12" class="pb-0">
                                      <v-menu
                                        v-model="menu_transfer_date"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            :value="computed_transfer_date"
                                            @change="(value) => (computed_transfer_date = value)"
                                            autocomplete="off"
                                            outlined
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            dense
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="select_transferDate"
                                          no-title
                                          :allowed-dates="
                                            (val) => val <= new Date().toISOString().substr(0, 10)
                                          "
                                          @input="menu_transfer_date = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" class="pb-0">
                                      <h4>{{ use_language.time }} :</h4>
                                    </v-col>
                                    <v-col cols="4" class="pb-0">
                                      <v-select
                                        dense
                                        v-model="select_transferHour"
                                        :items="transferHourItems"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                    <div class="pt-6">:</div>
                                    <v-col cols="4" class="pb-0">
                                      <v-select
                                        dense
                                        v-model="select_transferMinute"
                                        :items="transferMinuteItems"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-divider />
                                <v-card-actions class="py-5">
                                  <v-btn
                                    outlined
                                    color="primary"
                                    @click="submit_transferPayment()"
                                  >
                                    {{ use_language.add_item }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-row>
                              <v-col>
                                <v-data-table
                                  :headers="[
                                    {
                                      text: use_language.bank,
                                      value: 'bankAccountText',
                                      align: 'left',
                                      sortable: false,
                                    },
                                    {
                                      text: use_language.date_time,
                                      value: 'informDatetime',
                                      align: 'right',
                                      sortable: false,
                                    },
                                    {
                                      text: use_language.total_,
                                      value: 'amount',
                                      align: 'right',
                                      sortable: false,
                                    },
                                    {
                                      text: use_language.slip,
                                      value: 'photoLink',
                                      align: 'center',
                                      sortable: false,
                                      width: 10,
                                    },
                                    {
                                      text: use_language.delete,
                                      value: 'Manage',
                                      align: 'center',
                                      sortable: false,
                                      width: 10,
                                    },
                                  ]"
                                  :items="paymentListWithIndex"
                                  hide-default-footer
                                  class="packhai-border-table packhai-table"
                                >
                                  <template v-slot:item.photoLink="{ item }">
                                    <v-img
                                      :src="item.photoLink == null ? '' : item.photoLink"
                                      width="50"
                                      height="50"
                                      class="ma-1"
                                    ></v-img>
                                  </template>
                                  <template v-slot:item.Manage="{ item }">
                                    <v-btn icon color="red" @click="removeSlip(item.Index)"
                                      ><v-icon>mdi-delete-outline</v-icon></v-btn
                                    >
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- เลือกขนส่ง -->
                        <v-card class="v-card-bottom-30">
                          <v-card-title class="px-0">
                            <h4>{{ use_language.choose_transport }}</h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <v-select
                              v-model="orderMaster.ExpressUniqueID"
                              :items="rd_express_company_list"
                              item-text="expressCompanyName"
                              item-value="uniqueID"
                              persistent-hint
                              dense
                              hide-details
                              outlined
                              single-line
                              @change="get_data_express_cod_limit()"
                            >
                              <template slot="selection" slot-scope="data">
                                <img style="width: 60px" v-if="data.item.logoPic != null" :src="data.item.logoPic" />
                                <v-col class="ml-3 py-0">
                                  <span>{{ data.item.expressCompanyName }}</span>
                                </v-col>
                              </template>
                              <template slot="item" slot-scope="data">
                                <img style="width: 60px" v-if="data.item.logoPic != null" :src="data.item.logoPic" />
                                <v-col class="ml-3">
                                  <span>{{ data.item.expressCompanyName }}</span>
                                </v-col>
                              </template>
                            </v-select>

                            <v-row v-if="branch_id == 547 || branch_id == 586">
                              <v-col cols="12" md="3" lg="1"> TrackingNo : </v-col>
                              <v-col cols="12" md="4" lg="3">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  :value="input_trackingNo"
                                  @change="(value) => (input_trackingNo = value)"
                                  autocomplete="off"
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- ช่องทางการขาย -->
                        <v-card class="v-card-bottom-30">
                          <v-card-title class="px-0">
                            <h4>{{ use_language.sales_channel }} </h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <!-- <v-row>
                              <v-col cols="12" class="pb-0">
                                <h4>{{ use_language.channel }} :</h4>
                              </v-col>
                              <v-col
                                cols="12"
                                class="pb-0"
                                v-if="
                                  channel_source_list.length == 3 &&
                                  channel_source_list[0]['id'] == 1
                                "
                              >
                                <v-radio-group v-model="orderMaster.referenceSourceId" row>
                                  <v-radio label="" :value="1" class="mr-0"></v-radio>
                                  <img
                                    class="mr-3"
                                    src="https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/facebook.png"
                                    style="max-width: 40px"
                                  />
                                  <v-radio label="" :value="2" class="mr-0"></v-radio>
                                  <img
                                    class="mr-3"
                                    src="https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/line.png"
                                    style="max-width: 40px"
                                  />
                                  <v-radio label="" :value="7" class="mr-0"></v-radio>
                                  <img
                                    class="mr-3"
                                    src="https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/Instagram.png"
                                    style="max-width: 40px"
                                  />
                                </v-radio-group>
                              </v-col>
                              <v-col cols="12" v-else>
                                <v-select
                                  ref="orderMaster.referenceSourceId"
                                  :items="channel_source_list"
                                  v-model="orderMaster.referenceSourceId"
                                  item-key="id"
                                  item-value="id"
                                  dense
                                  hide-details
                                  outlined
                                >
                                  <template slot="selection" slot-scope="data">
                                    <v-col
                                      cols="1"
                                      class="px-0 py-0"
                                      v-if="data.item.logoPic != null"
                                    >
                                      <v-avatar size="25px">
                                        <img :src="data.item.logoPic" />
                                      </v-avatar>
                                    </v-col>
                                    <v-col cols="11" class="mx-0 py-0">
                                      <span>{{ data.item.channelName }}</span>
                                    </v-col>
                                  </template>
                                  <template slot="item" slot-scope="data">
                                    <v-avatar size="25px" v-if="data.item.logoPic != null">
                                      <img :src="data.item.logoPic" />
                                    </v-avatar>
                                    <v-col class="ml-3">
                                      <span>{{ data.item.channelName }}</span>
                                    </v-col>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row> -->
                            <v-row>
                              <v-col cols="12" class="pb-0">
                                <h4>{{ use_language.reference_number }} :</h4>
                              </v-col>
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  v-model="orderMaster.referenceNumber"
                                  outlined
                                  hide-details="auto"
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <!-- ที่อยู่ลูกค้า -->
                        <v-card class="v-card-bottom-30">
                          <v-card-title class="px-0">
                            <h4>{{ use_language.customer_address }}</h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <v-row>
                              <v-col cols="12" class="px-0">
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <h4>{{ use_language.address }} :</h4>
                                  </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-textarea
                                      v-model="input_shipping_address_api"
                                      hide-details="auto"
                                      dense
                                      :placeholder="
                                        use_language.paste_all_addresses_press_address
                                      "
                                      outlined
                                    >
                                      <template v-slot:label>
                                        <span>{{
                                          use_language.paste_all_addresses_press_address
                                        }}</span>
                                      </template>
                                    </v-textarea>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pb-0"> </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-btn
                                      class="info mb-6"
                                      :disabled="
                                        input_shipping_address_api == null ||
                                        input_shipping_address_api == ''
                                      "
                                      @click="address_api_click('shipping_address')"
                                      >{{ use_language.separate_address }}</v-btn
                                    >
                                  </v-col>
                                </v-row>

                                <v-row v-if="text_address_last != ''">
                                  <v-col cols="12" class="pb-0"></v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-btn text class="px-0" color="warning">{{
                                      text_address_last
                                    }}</v-btn>
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col cols="12">
                                    <h4>{{ use_language.tel }} :</h4>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                      :value="shippingAddress.phone"
                                      autocomplete="off"
                                      type="number"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="input_shipping_address_phone_rules"
                                      required
                                      @keyup.enter="$refs.txt_shippingAddressname.focus()"
                                      @blur="find_shipping_address_by_phone()"
                                      @change="(value) => (shippingAddress.phone = value)"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-dialog
                                  v-model="dialog_shipping_address"
                                  scrollable
                                  persistent
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title>
                                      {{ use_language.addresses_for_phone }}
                                      <v-spacer />
                                      <v-btn icon @click="bt_close_shipping_address_dialog()"
                                        ><v-icon>mdi-close</v-icon></v-btn
                                      >
                                    </v-card-title>
                                    <v-divider />
                                    <v-card-text class="pt-5">
                                      <v-radio-group
                                        v-model="rd_group_shipping_address_exist"
                                        class="mb-2"
                                      >
                                        <v-radio
                                          v-for="item in shipping_address_list_search_by_phone"
                                          :key="item.id"
                                          :label="item.fullAddress"
                                          :value="item"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                      <v-row justify="center">
                                        <v-btn
                                          color="info"
                                          @click="bt_select_shipping_address()"
                                          >{{ use_language.choose_address }}</v-btn
                                        >
                                      </v-row>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <h4>{{ use_language.name }} :</h4>
                                  </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-text-field
                                      ref="txt_shippingAddressname"
                                      autocomplete="off"
                                      :value="shippingAddress.name"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="input_shipping_address_name_rules"
                                      required
                                      @keyup.enter="$refs.txt_shippingaddress.focus()"
                                      @change="(value) => (shippingAddress.name = value)"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" class="pb-0">
                                    <h4>{{ use_language.address }} :</h4>
                                  </v-col>
                                  <v-col cols="12" class="pb-0">
                                    <v-text-field
                                      ref="txt_shippingaddress"
                                      autocomplete="off"
                                      :value="shippingAddress.address"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="input_shipping_address_rules"
                                      required
                                      @keyup.enter="
                                        $refs.shippingAddress.postcodeMappingId.focus()
                                      "
                                      @change="(value) => (shippingAddress.address = value)"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <h4>{{ use_language.zipcode }} :</h4>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-autocomplete
                                      ref="shippingAddress.postcodeMappingId"
                                      v-model="select_shiping_postcodeMappingId"
                                      :items="postcode_mapping"
                                      :item-text="
                                        (item) =>
                                          item.Postcode +
                                          ' ' +
                                          item.Province +
                                          ' ' +
                                          item.District +
                                          ' ' +
                                          item.SubDistrict
                                      "
                                      item-value="Id"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      :rules="select_shipping_address_mapping_rules"
                                      required
                                      return-object
                                      @change="set_data_postcodeMappingId()"
                                    >
                                      <template v-slot:label>
                                        <span>{{ use_language.all_address }}</span>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-btn
                              v-model="bt_submit"
                              color="primary"
                              :disabled="loading || !authorize"
                              @click="bt_submit_click()"
                              class="hidden-xs-only"
                              >{{ use_language.add_new_order }}</v-btn
                            >
                            <v-btn
                              v-model="bt_submit"
                              color="primary"
                              :disabled="loading || !authorize"
                              @click="bt_submit_click('mobile')"
                              class="hidden-sm-and-up"
                              >{{ use_language.add_new_order }}</v-btn
                            >
                          </v-card-text>
                        </v-card>

                        <!-- หมายเหตุ -->
                        <!-- <v-card class="v-card-bottom-30">
                          <v-card-title class="px-0">
                            <h4>{{ use_language.note }}</h4>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <v-textarea
                              hide-details
                              :value="orderMaster.remark"
                              outlined
                              @change="(value) => (orderMaster.remark = value)"
                            ></v-textarea>
                          </v-card-text>

                          <v-divider></v-divider>
                          <v-card-text>
                            <v-btn
                              v-model="bt_submit"
                              color="primary"
                              :disabled="loading || !authorize"
                              @click="bt_submit_click()"
                              class="hidden-xs-only"
                              >{{ use_language.add_new_order }}</v-btn
                            >
                            <v-btn
                              v-model="bt_submit"
                              color="primary"
                              :disabled="loading || !authorize"
                              @click="bt_submit_click('mobile')"
                              class="hidden-sm-and-up"
                              >{{ use_language.add_new_order }}</v-btn
                            >
                          </v-card-text>
                        </v-card> -->
                      </v-form>

                      <v-dialog
                        v-model="addOrderPopup.Dialog"
                        scrollable
                        :max-width="addOrderPopup.MaxWidth"
                        persistent
                        :fullscreen="$vuetify.breakpoint.xsOnly"
                      >
                        <v-card elevation="1" class="ma-0">
                          <v-card-title class="height-57 pa-0">
                            <div class="v-list-item">
                              <div class="pa-2">
                                <span
                                  ><h5>{{ use_language.search }} :</h5></span
                                >
                              </div>
                              <div class="v-list-item__content pa-2">
                                <v-text-field
                                  ref="refSearchStockModelPopup"
                                  :value="searchStockModelPopup"
                                  autocomplete="off"
                                  class="pl-2"
                                  dense
                                  hide-details
                                  @change="(value) => (searchStockModelPopup = Trim_value(value))"
                                  @keyup.enter="PopupsearchStock(limits, 0)"
                                ></v-text-field>
                              </div>
                              <div class="pa-2">
                                <v-btn
                                  color="primary"
                                  class="mr-1 px-2"
                                  @click="PopupsearchStock(limits, 0)"
                                >
                                  <v-icon center>mdi-magnify</v-icon>
                                  {{ use_language.search }}
                                </v-btn>
                              </div>
                              <div>
                                <v-btn icon @click="addOrderPopup.Dialog = false"
                                  ><v-icon color="danger">mdi-close</v-icon></v-btn
                                >
                              </div>
                            </div>
                          </v-card-title>
                          <v-divider />

                          <!-- PC -->
                          <v-card-text
                            class="overflow-x-auto pt-1 pb-1 hidden-xs-only"
                            :style="{ height: addOrderPopup.MaxHeight + 'px' }"
                          >
                            <v-data-table
                              :headers="addOrderPopup.headers"
                              :items="addOrderPopup.Data"
                              item-key="id"
                              :items-per-page="90000000000"
                              hide-default-footer
                              class="elevation-0 packhai-checkbox-select"
                            >
                              <template v-slot:item.selectProduct="{ item }">
                                <v-checkbox
                                  v-model="item.selectProduct"
                                  color="red"
                                  hide-details
                                  @click="
                                    ProductSelectCheckbox(item, addOrderPopup.Data.indexOf(item))
                                  "
                                ></v-checkbox>
                              </template>
                              <template v-slot:item.No="{ item }">
                                {{ addOrderPopup.Data.indexOf(item) + 1 + offsets }}
                              </template>
                              <template v-slot:item.photoLink="{ item }">
                                <v-btn
                                  v-if="item.photoLink != null && item.photoLink != ''"
                                  icon
                                  @click="OpenPhoto(item.photoLink)"
                                >
                                  <img :src="item.photoLink" height="30" />
                                </v-btn>
                              </template>
                              <template v-slot:item.name="{ item }">
                                {{ item.name }}
                              </template>
                              <template v-slot:item.prop1="{ item }">
                                <span class="fn-13">
                                  {{
                                    (item.prop1_Description != null &&
                                    item.prop1_Description != ""
                                      ? item.prop1_Description
                                      : "") +
                                    (item.prop2_Description != null &&
                                    item.prop2_Description != ""
                                      ? " , " + item.prop2_Description
                                      : "")
                                  }}
                                </span>
                              </template>
                              <template v-slot:item.salePrice="{ item }">
                                <span class="bold-color-red">
                                  {{
                                    formatMoney(
                                      item.salePrice == null ||
                                        item.salePrice == "" ||
                                        item.salePrice == 0
                                        ? "0.00"
                                        : item.salePrice
                                    )
                                  }}
                                </span>
                              </template>
                              <template v-slot:item.quantityAvailable="{ item }">
                                <span class="fw-b">
                                  {{ formatMoney(item.quantityAvailable) }}
                                </span>
                              </template>
                            </v-data-table>
                          </v-card-text>
                          <!-- PC -->

                          <!-- Mobile -->
                          <v-card-text
                            class="overflow-x-auto pt-1 pb-1 hidden-sm-and-up"
                            :style="{ height: addOrderPopup.MaxHeight + 'px' }"
                          >
                            <v-data-iterator
                              :items="addOrderPopup.Data"
                              :items-per-page="900000000"
                              hide-default-header
                              hide-default-footer
                            >
                              <template v-slot:default="props">
                                <v-card v-for="item in props.items" :key="item.id" class="mt-4">
                                  <v-card-text class="pa-0">
                                    <v-row>
                                      <v-col cols="12">
                                        <b> {{ item.name }}</b>
                                      </v-col>
                                      <v-col cols="3">
                                        <a
                                          v-if="item.photoLink != null && item.photoLink != ''"
                                          @click="OpenPhoto(item.photoLink)"
                                        >
                                          <img :src="item.photoLink" style="width: 100%" />
                                        </a>
                                        <a v-else style="width: 100%">
                                          <span class="bold-color-gray-12"> No Picture </span>
                                        </a>
                                      </v-col>
                                      <v-col cols="9">
                                        <v-row class="pa-0">
                                          <v-col cols="12" class="pa-0">
                                            <span
                                              v-if="
                                                item.prop1_Description != null &&
                                                item.prop1_Description != ''
                                              "
                                            >
                                              {{ use_language.type }} :
                                              {{
                                                (item.prop1_Description != null &&
                                                item.prop1_Description != ""
                                                  ? item.prop1_Description
                                                  : "") +
                                                (item.prop2_Description != null &&
                                                item.prop2_Description != ""
                                                  ? " , " + item.prop2_Description
                                                  : "")
                                              }}</span
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row class="pa-0">
                                          <v-col cols="12" class="pa-0">
                                            <span v-if="item.sku != null && item.sku != ''">
                                              {{ use_language.sku }} : {{ item.sku }}</span
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row class="pa-0">
                                          <v-col cols="12" class="pa-0">
                                            <span
                                              v-if="item.barcode != null && item.barcode != ''"
                                            >
                                              {{ use_language.barcode }} :
                                              {{ item.barcode }}</span
                                            >
                                          </v-col>
                                        </v-row>

                                        {{ use_language.remaining }} :
                                        {{ formatMoney(item.quantityAvailable) }}
                                      </v-col>
                                      <v-col cols="12">
                                        <v-btn
                                          v-if="item.selectProduct"
                                          style="width: 100%"
                                          left
                                          depressed
                                          color="primary"
                                          @click="
                                            ProductSelect(
                                              item,
                                              addOrderPopup.Data.indexOf(item),
                                              check_data()
                                            )
                                          "
                                        >
                                          <v-icon left>
                                            mdi-checkbox-multiple-marked-circle-outline</v-icon
                                          >
                                          {{ use_language.choose }}
                                        </v-btn>
                                        <v-btn
                                          v-else
                                          outlined
                                          depressed
                                          color="primary"
                                          style="width: 100%"
                                          @click="
                                            ProductSelect(item, addOrderPopup.Data.indexOf(item)),
                                              check_data()
                                          "
                                        >
                                          {{ use_language.choose }}
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </template>
                            </v-data-iterator>
                          </v-card-text>
                          <!-- Mobile -->

                          <div
                            v-if="pageLength > 1 && addOrderPopup.Data.length > 0"
                            class="text-center pa-1"
                            @click="changePage()"
                          >
                            <v-pagination
                              v-model="page"
                              :length="pageLength"
                              :total-visible="pageTotalVisible"
                              color="error"
                            ></v-pagination>
                          </div>

                          <v-divider />
                          <div class="height-57 pa-2" align="right">
                            <v-btn
                              color="primary"
                              class="mr-2"
                              :loading="addOrderPopup.loading"
                              @click="Submit_addOrderPopup()"
                            >
                              {{ use_language.ad_product }}
                            </v-btn>
                          </div>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="add_address_invoice_dialog"
                        persistent
                        scrollable
                        max-width="800"
                      >
                        <v-card elevation="1" class="ma-0">
                          <v-card-title>
                            <h4>เพิ่มที่อยู่ใบกำกับภาษี</h4>
                            <v-spacer />
                            <v-btn icon @click="add_address_invoice_dialog = false"
                              ><v-icon color="danger">mdi-close</v-icon></v-btn
                            >
                          </v-card-title>
                          <v-divider> </v-divider>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <h4>{{ use_language.tax_invoice_number }} :</h4>
                              </v-col>
                              <v-col cols="12" sm="8" lg="8" md="8" class="pb-0">
                                <v-text-field
                                  ref="txt_taxid"
                                  autocomplete="off"
                                  :value="address_invoice.taxID"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  required
                                  @keyup.enter="$refs.txt_address_invoice_name.focus()"
                                  @change="(value) => (address_invoice.taxID = value)"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <h4>{{ use_language.name }} :</h4>
                              </v-col>
                              <v-col cols="12" sm="8" lg="8" md="8" class="pb-0">
                                <v-text-field
                                  ref="txt_address_invoice_name"
                                  autocomplete="off"
                                  :value="address_invoice.name"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  required
                                  @keyup.enter="$refs.txt_address_invoice_tel.focus()"
                                  @change="(value) => (address_invoice.name = value)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <span class="red--text" v-if="address_invoice_validate.name"
                                  >กรุณาระบุ</span
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <h4>{{ use_language.tel }} :</h4>
                              </v-col>
                              <v-col cols="12" sm="8" lg="8" md="8" class="pb-0">
                                <v-text-field
                                  ref="txt_address_invoice_tel"
                                  :value="address_invoice.phone"
                                  autocomplete="off"
                                  type="number"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  required
                                  @keyup.enter="$refs.txt_address_invoice_address.focus()"
                                  @change="(value) => (address_invoice.phone = value)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <span class="red--text" v-if="address_invoice_validate.phone"
                                  >กรุณาระบุ</span
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <h4>{{ use_language.address }} :</h4>
                              </v-col>
                              <v-col cols="12" sm="8" lg="8" md="8" class="pb-0">
                                <v-text-field
                                  ref="txt_address_invoice_address"
                                  autocomplete="off"
                                  :value="address_invoice.address"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  required
                                  @keyup.enter="
                                    $refs.cb_address_invoice_postcodeMappingId.focus()
                                  "
                                  @change="(value) => (address_invoice.address = value)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <span class="red--text" v-if="address_invoice_validate.address"
                                  >กรุณาระบุ</span
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="2" lg="2" md="2">
                                <h4>{{ use_language.zipcode }} :</h4>
                              </v-col>
                              <v-col cols="12" sm="8" lg="8" md="8">
                                <v-autocomplete
                                  ref="cb_address_invoice_postcodeMappingId"
                                  v-model="select_address_invoice_postcodeMapping"
                                  :items="postcode_mapping"
                                  :item-text="
                                    (item) =>
                                      item.Postcode +
                                      ' ' +
                                      item.Province +
                                      ' ' +
                                      item.District +
                                      ' ' +
                                      item.SubDistrict
                                  "
                                  item-value="Id"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  required
                                  return-object
                                  @change="set_data_address_invoice_postcodeMappingId()"
                                >
                                  <template v-slot:label>
                                    <span>{{ use_language.all_address }}</span>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="2" lg="2" md="2" class="pb-0">
                                <span class="red--text" v-if="address_invoice_validate.postcode"
                                  >กรุณาระบุ</span
                                >
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider> </v-divider>
                          <v-card-actions class="text-right">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="submit_address_invoice()">
                              บันทึก
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="dialog_not_enough_stock"
                        persistent
                        scrollable
                        max-width="800"
                      >
                        <v-card elevation="1" class="ma-0">
                          <v-card-title>
                            <v-spacer />
                            <v-btn icon @click="dialog_not_enough_stock = false"
                              ><v-icon color="danger">mdi-close</v-icon></v-btn
                            >
                          </v-card-title>
                          <!-- <v-divider/> -->
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" class="pt-0">
                                <span style="color: red">*** {{ dialog_massage }}</span>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" class="py-0">
                                <v-data-table
                                  :headers="[
                                    {
                                      text: 'รูป',
                                      value: 'photoLink',
                                      align: 'center',
                                      sortable: false,
                                    },
                                    {
                                      text: 'สินค้า',
                                      value: 'name',
                                      align: 'left',
                                      sortable: false,
                                      width: 200,
                                    },
                                    {
                                      text: 'จำนวนที่มี',
                                      value: 'quantityAvailable',
                                      align: 'right',
                                      sortable: false,
                                    },
                                    {
                                      text: 'ที่ต้องใช้',
                                      value: 'quantityRequest',
                                      align: 'right',
                                      sortable: false,
                                    },
                                    {
                                      text: 'ขาดสต๊อก',
                                      value: 'quantityShortage',
                                      align: 'right',
                                      sortable: false,
                                      width: 10,
                                    },
                                  ]"
                                  :items="orderItemProductResponse"
                                  :items-per-page="10000"
                                  hide-default-footer
                                  class="packhai-border-table packhai-table"
                                >
                                  <template v-slot:item.photoLink="{ item }">
                                    <img
                                      v-if="item.photoLink != null"
                                      style="width: 50px"
                                      :src="item.photoLink"
                                      @click="image_click(item.photoLink)"
                                    />
                                  </template>
                                  <template v-slot:item.name="{ item }">
                                    <span>{{ item.name }}</span
                                    ><br />
                                    <span style="color: #a9a9a9">SKU : {{ item.sku }}</span
                                    ><br />
                                    <span style="color: #a9a9a9"
                                      >ลักษณะ : {{ item.propDescription }}</span
                                    >
                                  </template>
                                  <template v-slot:item.quantityAvailable="{ item }">
                                    {{ format_number(item.quantityAvailable) }}
                                  </template>
                                  <template v-slot:item.quantityRequest="{ item }">
                                    {{ format_number(item.quantityRequest) }}
                                  </template>
                                  <template v-slot:item.quantityShortage="{ item }">
                                    <span style="color: red">{{
                                      format_number(item.quantityShortage)
                                    }}</span>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>

                      <v-dialog v-model="ShowPhoto" max-width="500">
                        <v-card class="ma-0">
                          <v-card-text>
                            <v-row align="center">
                              <v-col cols="12" class="text-center">
                                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
                                <img
                                  class="hidden-sm-and-down"
                                  :src="ShowPhotoLink"
                                  width="450"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider />
                          <v-card-actions>
                            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{
                              use_language.newtab
                            }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="#1976d2" @click="ShowPhoto = false">{{
                              use_language.close
                            }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </div>
                </div>

                <!-- history -->
                <div v-if="item == 'ประวัติ'">
                  <br>
                  <div v-if="historyLoading == false"  class="px-5" align="center">
                    <v-progress-circular
                      indeterminate
                      color="red darken-2"
                    ></v-progress-circular>
                  </div>
                  <div v-else @scroll="handleScrollHistory" class="scroll-container-col3" :style="{ height: (window.height-220) + 'px' }">
                    <div v-for="item in customerHistoryList" :key="item.length">
                      <v-row>
                        <v-col class="pb-0">
                          <div class="d-flex">
                            <h4  @click="detail_page(item.id)">
                              <a style="text-decoration: underline;color: #626262"  >{{format_order_id(item.id)}}</a>
                            </h4>
                            <v-avatar size="20" class="ml-2" >
                              <v-img :src="item.channelLogo" />
                            </v-avatar>
                            </div>
                          
                        </v-col>
                        <v-col class="text-right pb-0">
                          <h3>{{format_price(item.orderTotalAmount)}} ฿</h3> 
                           
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pb-0">
                          <small   style="color:grey">{{formatDatetime_new(item.createdDatetime)}}</small>
                        </v-col>
                        <v-col class="text-right pt-0 pb-0">
                        
                          <small> 
                            <span v-if="item.shippingStatusID == 10" style="color: black;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 20" style="color: #E40078;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 30" style="color: #ff7ac0;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 40" style="color: orange;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 50" style="color: blue;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 60" style="color: #9acd32;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 70" style="color: green;">{{ item.shippingStatusName }}</span>
                            <span v-if="item.shippingStatusID == 90" style="color: red;">{{ item.shippingStatusName }}</span>
                          </small>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <table style="width:100%">
                            <tr>
                              <td>
                                <img style="width: 50px" :src="item.expressCompanyLogo"  />
                                <span color="red" style="color:red" v-if="!item.isCOD"><v-icon size="10" class="ml-3" color="red">mdi-checkbox-blank-circle</v-icon> COD</span>
                              </td>
                              <td class="pl-3 text-right">
                                <span v-if="!item.trackingNo"> {{ item.trackingNo }}</span>
                                 
                              </td>
                            </tr>
                          </table>
                          
                         
                        </v-col>
                      
                      </v-row>
                      <hr class="history1">
                    </div>
                    <div v-if="scrollHistoryLoad == false" class="px-5" align="center">
                      <v-progress-circular
                        indeterminate
                        color="red darken-2"
                      ></v-progress-circular>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- <v-card id="app">
      <h1>Vue.js with Socket.io</h1>
      <ul>
        <li v-for="(msg, index) in messages" :key="index">
          {{ msg.from }}: {{ msg.message }}
        </li>
      </ul>
      <input
        v-model="message"
        @keyup.enter="sendMessage"
        placeholder="Type a message"
      />
      <input v-model="to" placeholder="Send to (custom ID)" />
      <button @click="sendMessage">Send</button>
    </v-card> -->
  </div>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns'
import Loading from '@/website/components/Loading'
import { format_number, format_order_id, format_price, isNumberWNoDot, isNumberWithDot, formatMoney, Trim_value, Trim_rules,get_languages, DateNowNotNull, formatDatetime_new} from '@/website/global_function'
import { shippingStatusDict } from '@/website/statusDict'
import { add_order_language } from "@/website/language/add_order_language";
import { addOrderService, shopService_dotnet, orderService_dotnet, stockService_dotnet, generalService_dotnet,  expressService_dotnet, customerWebsite } from '@/website/global'
import axios from "axios";
import { token_api } from "@/website/backend/token";
import * as signalR from "@microsoft/signalr";

// import socket from "src/socket.js";
// import io from "socket.io-client";

// const customId = 123; // Replace with the actual custom ID logic

// const socket = io("http://localhost:3000", {
//   query: { customId },
// });

export default {
  components: { Loading },
  data: () => ({
    // socket.io
    // message: "",
    // to: "",
    // messages: [],

    // mockup chat
    panel: [2],

    selected: [],
    activeChat: false,

    //real chat
    shopID: JSON.parse(localStorage.getItem("shop_id")),
    staffID: JSON.parse(localStorage.getItem("staff_id")),
    panelChannel: [1],
    selectChannelItem: [],
    channelList: [],
    channelInfo: null,

    channelTitleList: [],
    messageTitleLength: null,

    channelSourceId: null,
    platformId: null,
    platformName: '',
    channelIsAll: null,
    channelProfilePic:  null,

    shopCustomerIDFromChat: null,
    customerHistoryList: [],
    customerHistoryLength: 0,
    scrollHistoryLoad: true,

    socketConnectionID: '',

    messageInfo: [],
    messageInfoOfTitle: [],
    chatIdForSendMessage: 0,
    messageForSendMessage: '',
    messageDetailLength: null,

    staffShopChat: null,

    contentLoadCount: 0,
    contentLoadTotal: 0,

     // แสดงภาพ / วิดีโอ
    showPhotoChat: false,
    showPhotoObj: "",

    showVideoChat: false,
    showVideoObj: "",
    loadVideo: false,

    loadImage: false,

    tab: null,
    tab_items: ["แท็ก", "สร้างออร์เดอร์", "ประวัติ"],
    historyLoading: true,

    //add order
    //#region
    chatIdForPush: 0,
    newOrderListOfChat: [],
    newOrderListDefault: [],
    shopAddressIDForDefault: null,

    data: {},
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    use_language: null,
    set_language: null,
    authorize: false,
    orderMaster: {
      shopId: null,
      branchId: null,
      createdByStaffId: null,
      salemanId: null,
      shippingStatusId: 0,
      totalPrice: null,
      deliveryCost: null,
      orderDiscountPercent: null,
      orderDiscount: null,
      vatType: null,
      vatpercent: null,
      vatAmount: null,
      shopAddressId: null,
      shippingAddressId: null,
      shippingAddressInvoiceId: null,
      isCOD: null,
      codamount: null,
      remark: "",
      shopBankAccountId: null,
      expressCompanyId: null,
      branchExpressID: null,
      shopExpressID: null,
      referenceSourceId: 0,
      warehouseId: null,
      referenceNumber: null,
      taxID: null,
      ExpressUniqueID: null,
      chatTitleID: null
    },

    orderItem: [],
    orderItem_dict: {
      id: 0,
      orderMasterId: null,
      stockShopId: null,
      quantity: null,
      salePrice: null,
      discountPercent: null,
      discount: null,
      isLock: true,
      productMasterID: null,
      productMasterItemID: null,
      isProductSet: false,
    },

    shopBankAccount: {
      id: 0,
      shopId: null,
      bankId: null,
      bankName: "",
      accountNumber: "",
      accountName: "",
      isActive: true,
      isDefault: true,
      isDeleted: false,
    },
    shippingAddress: {
      id: 0,
      shopId: 0,
      name: "",
      phone: "",
      address: "",
      postcodeMappingId: 0,
      subDistrict: "",
      district: "",
      provinceId: 0,
      province: "",
      postcode: "",
      shopCustomerID: 0,
      customerFacebook: "",
      customerLine: "",
      customerEmail: "",
    },
    oldShippingAddress: {
      id: 0,
      shopId: 0,
      name: "",
      phone: "",
      address: "",
      postcodeMappingId: 0,
      subDistrict: "",
      district: "",
      provinceId: 0,
      province: "",
      postcode: "",
      shopCustomerID: 0,
      customerFacebook: "",
      customerLine: "",
      customerEmail: "",
    },
    shopAddress: {
      id: 0,
      shopId: 0,
      name: "",
      phone: "",
      address: "",
      postcodeMappingId: 0,
      tumbon: "",
      amphoe: "",
      province: "",
      postcode: "",
      isDefault: true,
      isDeleted: false,
    },
    orderPayment: null,
    orderPayment_dict: {
      id: 0,
      orderMasterId: 0,
      bankAccountText: "",
      amount: 0,
      photoLink: "",
      informDatetime: "",
      createdDatetime: "",
      approved: null,
      approvedBy: null,
      disApproved: null,
      disApprovedBy: null,
      updateDateTime: "",
    },

    text_address_last: "",
    rd_is_cod_rule: [],
    select_address_invoice_postcodeMapping: null,
    select_shiping_postcodeMappingId: "",
    input_transferFileUpload_valid: false,
    ConditionAddOrder: null,
    data_tmp: {
      StockList: [],
    },
    searchStockLoading: false,
    selectProduct_valid: false,
    ProductPrice: 0,

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> old

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // คลัง
    Warehouse_List: [],
    Warehouse: {},

    itemsPerPage: 4,
    items: [
      {
        name: "Frozen Yogurt",
      },
      {
        name: "Ice cream sandwich",
      },
    ],

    address_invoice_validate: {
      phone: false,
      name: false,
      address: false,
      postcode: false,
    },

    // Add products
    searchStockModel: null,
    hideNoDataSearchStock: true,
    searchInputStock: null,
    productList: [],
    totalVat: 0,
    textField_deliveryCost: null,
    select_discountType: "฿",
    textField_discount: null,
    totalDiscount: 0,
    totalPrice: 0,
    select_vatType: 1,
    textarea_remarkProduct: null,
    add_address_invoice_dialog: false,
    // Permission
    permission_manage_bank_account: null,
    // System
    page_loading: true,
    loading: false,
    search_product: null,
    select_shop_address_id_exist: null,
    select_shipping_address_id_exist: null,
    bt_submit: "add_order",
    dialog_add_stock_order: false,
    // Data
    shippingStatusDict,
    // postcode_mapping: PostcodeMapping,
    postcode_mapping: [],
    order_token: null,
    order_master: null,
    customer_type: null,
    express_company_list: null,
    table_headers: [
      { text: "รูป", value: "photoLink", align: "center", sortable: false },
      { text: "ชื่อ", value: "name", align: "left", sortable: false },
      {
        text: "จำนวน",
        value: "QuantityAvailable",
        align: "right",
        sortable: false,
      },
      {
        text: "จำนวนที่จะส่ง",
        value: "InputQuantity",
        align: "center",
        sortable: false,
        width: "15%",
      },
      {
        text: "ราคา",
        value: "salePrice",
        align: "center",
        sortable: false,
        width: "20%",
      },
    ],
    table_items: [],
    all_stock_except_stock_order: [],
    input_total_price: null,
    input_delivery_cost: null,
    // รูปแบบพัสดุ
    // orderMaster.codamount: null,
    rd_bank: "bank_other",
    select_bank_exist: null,
    bank_exist_list: [],
    bank_exist_list2: [],
    select_bank: null,
    bank_list: [
      { id: 1, text: "ธนาคารกสิกรไทย" },
      { id: 2, text: "ธนาคารไทยพาณิชย์" },
      { id: 3, text: "ธนาคารกรุงเทพ" },
      { id: 4, text: "ธนาคารกรุงไทย" },
      { id: 5, text: "ธนาคารกรุงศรีอยุธยา" },
      { id: 6, text: "ธนาคารทหารไทย" },
      { id: 7, text: "ธนาคารธนชาต" },
      { id: 8, text: "ธนาคารออมสิน" },
    ],
    header_customer_invoice: [
      // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
      {
        text: "เลือก",
        align: "left",
        value: "selectInvoice",
        sortable: false,
        width: "60px",
      },
      {
        text: "เลขผู้เสียภาษี",
        align: "left",
        value: "taxID",
        sortable: false,
        width: "100px",
      },
      {
        text: "ชื่อ",
        align: "left",
        value: "name",
        sortable: false,
        width: "100px",
      },
      {
        text: "เบอร์โทร",
        align: "left",
        value: "phone",
        sortable: false,
        width: "100px",
      },
      {
        text: "ที่อยู่",
        align: "left",
        value: "fullAddress",
        sortable: false,
        width: "200px",
      },
    ],
    dataItem_customer_invoice: [],
    customer_invoice_selected: null,
    input_account_name: null,
    input_account_number: null,
    // จัดส่งโดย
    rd_express_company_list: [],
    // ข้อมูลลูกค้า

    // input_channel_line: null,
    // input_channel_facebook: null,
    // input_channel_email: null,
    // ที่อยู่ผู้ส่ง
    shop_address_list: [],
    rd_group_shop_address: "new",
    input_shop_address_api: null,
    input_shop_address_phone: "",
    input_trackingNo: null,
    shop_address_list_search_by_phone: [],
    dialog_shop_address: false,
    rd_group_shop_address_exist: null,
    latest_shop_add_order_item: null,
    input_shop_address_name: "",
    input_shop_address: "",
    select_shop_address_mapping: "",
    // ที่อยู่ผู้รับ
    input_shipping_address_api: null,
    shipping_address_list_search_by_phone: [],
    dialog_shipping_address: false,
    rd_group_shipping_address_exist: null,
    latest_shipping_add_order_item: null,
    // หมายเหตุ
    saleman_list: [],
    // หมายเหตุ
    // orderMaster.remark: null,
    // url สำหรับลูกค้า
    customer_url: null,
    // rules
    input_total_price_rules: [],
    input_cod_amount_rules: [],
    select_bank_exist_rules: [],
    select_bank_rules: [],
    input_account_name_rules: [],
    input_account_number_rules: [],
    rd_group_express_company_rules: [],
    select_shop_address_rules: [],
    input_shop_address_phone_rules: [],
    input_shop_address_name_rules: [],
    input_shop_address_rules: [],
    select_shop_address_mapping_rules: [],
    input_shipping_address_phone_rules: [],
    input_shipping_address_name_rules: [],
    input_shipping_address_rules: [],
    select_shipping_address_mapping_rules: [],
    address_invoice: {
      taxID: null,
      id: null,
      name: null,
      phone: null,
      address: null,
      subDistrict: null,
      district: null,
      province: null,
      postcode: null,
      postcodeMappingId: null,
    },
    rd_is_cod: null,
    // transfer
    dialog_transfer: false,
    rdGroup_transferBankAccount: null,
    input_transferFileUpload: null,
    input_transferAmount: null,
    menu_transfer_date: false,
    select_transferDate: null,
    select_transferHour: null,
    transferHourItems: [
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11',
      '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
    ],
    select_transferMinute: null,
    transferMinuteItems: [
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
      '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
      '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
      '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
      '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
      '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
    ],
    transferDialogFormValid: true,

    // windowSize
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 15,
    offsets: 0,

    // popup
    Popupsearch: null,
    searchStockModelPopup: null,
    addOrderPopup: {
      MaxWidth: "50%",
      MaxHeight: 90,
      Dialog: false,
      headers: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "No",
          width: "60px",
        },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },
        { text: "ลักษณะ", align: "center", sortable: false, value: "prop1" },
        {
          text: "รหัสสินค้า",
          align: "center",
          sortable: false,
          value: "productCode",
          width: "90px",
        },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        { text: "Barcode", align: "center", sortable: false, value: "barcode" },
        {
          text: "ราคา",
          align: "right",
          sortable: false,
          value: "salePrice",
          width: "90px",
        },
        {
          text: "คงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
          width: "90px",
        },
      ],
      Select_Product: [],
      Data: [],
      ProductID_List: [],
      loading: false,
    },

    vat_type: null,
    channel_source_list: [],

    db_submit_check: false,
    quantityInput_error: null,
    quantityInput_status: null,

    dialog_not_enough_stock: false,
    orderItemProductResponse: [],
    dialog_massage: null,

    CODLimit: 0,

    // เอกสาร
    dialog_doc: false,
    input_doc_select: null,
    document_data: [],
    input_docFileUpload: null,
    input_docFileUpload_valid: false,
    //#endregion
  }),

  destroyed() {
    // windowSize
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted() {
    this.scrollStartBottom();
    // const connection = new signalR.HubConnectionBuilder()
    //   .withUrl("https://dev-express.packhai-api-88.com/oasysnoti", {
    //   // .withUrl("https://dev-express.packhai-api-88.com/oasysnoti", {
    //     skipNegotiation: true, // ข้ามการต่อรอง
    //     transport: signalR.HttpTransportType.WebSockets // ใช้ WebSockets โดยตรง
    //   })
    //   .build();

    // // ตั้งค่าการฟัง event จาก SignalR Hub
    // connection.on("ReceiveWebhookNotification", (data) => {
    //   this.getMessageFromSocket(data)
    // });

    // // เริ่มการเชื่อมต่อ
    // // connection.start()
    // //   .catch(err => {
    // //     console.error('Connection failed: ', err)
    // //     // this.reConnectSocket()
    // //   });

    // // connection.onclose(() => {
    // //   // this.reConnectSocket()
    // // });

    // async function start() {
    //   try {
    //     await connection.start();
    //   } catch (err) {
    //     setTimeout(start(), 5000);
    //   }
    // }

    // connection.onclose(async () => {
    //   await start();
    // })

    // // Start the connection.
    // start()

    window.addEventListener('beforeunload', this.closeSocket);

  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.closeSocket);
  },

  async created() {

    this.connectSocket()

    this.getChatChannel();

    this.GetStaffShop()

    //เก็บภาษามาไว้ใน use_language
    this.use_language = await this.get_languages(
      localStorage.getItem("set_language")
    );

    //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
    this.get_authorize_staffshop();

    // windowSize
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.window.width < 960) {
      this.addOrderPopup.MaxWidth = "100%";
    } else {
      this.addOrderPopup.MaxWidth = "70%";
    }
    if (this.size == "xs" || this.size == "sm") {
      this.limits = 5;
      this.addOrderPopup.MaxHeight = (90 * this.window.height) / 100;
    } else {
      this.addOrderPopup.MaxHeight =
        (this.addOrderPopup.MaxHeight * this.window.height) / 100;
      // cal limits
      this.limits = parseInt(
        (this.addOrderPopup.MaxHeight - 57 - 52 - 56 - 48) / 48
      );
    }

    // get get_postcode_mapping
    await this.get_postcode_mapping();

    this.shop_id = localStorage.getItem("shop_id");
    this.staff_id = localStorage.getItem("staff_id");
    this.branch_id = localStorage.getItem("branch_id");
    this.authorization_token = localStorage.getItem("authorization_token");

    if (this.$route.path.includes("chat-new") || this.$route.path.includes("inbox")) {
      await this.load_data();
      this.page_loading = false;
    }
    // get ข้อมูลเก่าจาก order_id
    this.order_id_ref = this.$route.query.ref;
    if (typeof this.order_id_ref !== "undefined") {
      if (this.order_id_ref != "") {
        this.get_data_order_by_ref();
      }
    }

    // set staff_id
    this.orderMaster.createdByStaffId = this.staff_id;
    this.rd_shop_address_changed();
    this.update_warehouseID();
    this.get_data_express_cod_limit();

    this.db_submit_check = false;
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

    transferDateFormatted() {
      return this.formatDate(this.date_transfer);
    },

    ////////////////////////////////////////////////////////////////

    computed_transfer_date() {
      return this.formatDate(this.select_transferDate);
    },

    paymentListWithIndex() {
      if (this.orderPayment != null) {
        return this.orderPayment.map((items, index) => ({
          ...items,
          Index: index,
        }));
      } else {
        return this.orderPayment;
      }
    },
  },

  watch: {
    async searchStockModel(value) {
      await this.searchStockModel_push(value);
    },

    textField_deliveryCost(value) {
      this.textField_deliveryCost = Number(value).toFixed(2);

      // cod เปลี่ยนตาม ยอดรวม
      this.orderMaster.codamount =
        this.totalPrice +
        Number(this.textField_deliveryCost) -
        this.totalDiscount +
        (this.select_vatType == 3 ? this.totalVat : 0);
    },

    textField_discount(value) {
      this.textField_discount = Number(value).toFixed(2);
    },

    textfield_transferAmount(value) {
      this.textfield_transferAmount = Number(value).toFixed(2);
    },

    rdgroup_searchAddressByPhone(value) {
      var addressID = value.ID;
      this.loading = true;

      this.loading = false;
    },

    productList: function () {
      this.set_stock_id_selected();
    },

    ////////////////////////////////////////////////////////////

    input_total_price: function () {
      this.input_total_price_rules = [];
      this.calculate_cod_amount();
    },

    input_delivery_cost: function () {
      this.calculate_cod_amount();
    },
  },

  methods: {
    formatDatetime_new,
    //real api
    // async reConnectSocket() {
    //   const connection = new signalR.HubConnectionBuilder()
    //     .withUrl("https://dev-express.packhai-api-88.com/oasysnoti", {
    //       skipNegotiation: true, // ข้ามการต่อรอง
    //       transport: signalR.HttpTransportType.WebSockets // ใช้ WebSockets โดยตรง
    //     })
    //     .build();

    //   // เริ่มการเชื่อมต่อ
    //   connection.start()
    //     .then(() => {})
    //     .catch(err => {
    //       this.reConnectSocket()
    //     });

    //   connection.onclose(() => {
    //     this.reConnectSocket()
    //   });
    // },

    async connectSocket() {
      this.connection = new WebSocket("wss://25nfmzc76e.execute-api.ap-southeast-1.amazonaws.com/production")

      this.connection.onmessage = (event) => {
        var text = event.data.slice(0, 9);
        if (text == 'ConnectId') {
          this.registerSocket(event)
        } else {
          this.getMessageFromSocket(event)
        }
      }

      this.connection.onopen = (event) => {
      };

      this.connection.onclose = (event) => {
        console.log('WebSocket connection closed!');
        this.connectSocket()
      };
    },

    async closeSocket(){
      this.connection.close();
      let response = await axios.post(
        expressService_dotnet + "CoreMessage/update-connetionid",
        {
          "shopId": this.shopID,
          "staffShopId": this.staffID,
          "connectionId": this.socketConnectionID,
          "isOnline": false
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token_api,
          },
        }
      );
      if (response.data.status == 'success') {
        console.log('close socket success');
      }
    },

    async registerSocket(item) {
      this.socketConnectionID = item.data.slice(12);
      let response = await axios.post(
        expressService_dotnet + "CoreMessage/update-connetionid",
        {
          "shopId": this.shopID,
          "staffShopId": this.staffID,
          "connectionId": this.socketConnectionID,
          "isOnline": true
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token_api,
          },
        }
      );
      if (response.data.status == 'success') {
        console.log('register success');
      }
    },

    async getChatChannel() {
      let response = await axios.post(
        expressService_dotnet + "CoreMessage/get-chat-channel-list?shopId=" + this.shopID,
        // 'https://dev-express.packhai-api-88.com/CoreMessage/get-chat-channel-list?shopId=' + this.shopID,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token_api,
          },
        }
      );
      this.channelList = response.data.data
      this.selectChannel(response.data.data[0])
      this.selectChannelItem = response.data.data[0]
    },

    selectChannel(item) {
      this.platformId = item.platformId
      this.channelInfo = item;
      // this.getChatMessageTitle(item);
      this.getChatTitle(item)
    },

    async getChatTitle(item) {
      this.chatIdForSendMessage = 0
      this.messageDetailLength = null
      this.messageInfo = []
      this.messageInfoOfTitle = []

      var reqPlatform = [{
        platformConnectionID: item.platformConnectionID,
        platformID: item.platformID
      }]
      let response = await axios.post(
        expressService_dotnet + "CoreMessage/get-chat-title-asyncs",
        // 'https://dev-express.packhai-api-88.com/CoreMessage/get-chat-title-asyncs',
        {
          shopId: this.shopID,
          platform: reqPlatform,
          skip: 0,
          take: 20,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token_api,
          },
        }
      );

      this.channelTitleList = response.data.data
      this.messageTitleLength = this.channelTitleList.length
      this.channelIsAll = response.data.isAll
      this.activeChat = false
      this.selected = []
    },

    async getChatMessageDetail(item) {
      if (this.chatIdForSendMessage != parseInt(item.chatID)) {
        this.historyLoading = false
        this.customerHistoryList = []
        if (item.platformID == 1 || item.platformID == 2) {
          this.tab_items = ["แท็ก", "สร้างออร์เดอร์", "ประวัติ"]
        } else {
          this.tab_items = ["แท็ก", "ประวัติ"]
        }
        this.pushOrder(item)
        this.chatIdForPush = await this.chatIdForSendMessage
        this.channelSourceId = item.referenceSourceID
        this.channelProfilePic = item.platformShopProfilePicture
        this.platformId = item.platformID
        this.messageInfoOfTitle = item
        this.contentLoadCount = 0
        this.chatIdForSendMessage = parseInt(item.chatID)
        this.selected = item
        this.shopCustomerIDFromChat = item.shopCustomerID
        this.setPlatformName()
        await this.logAddOder()
        this.messageInfo = []
        this.messageDetailLength = 0
        let response = await axios.post(
          expressService_dotnet + "CoreMessage/get-chat-message-detail-asyncs",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            skip: 0,
            take: 10,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );
        this.messageInfo = response.data.messages.reverse()
        this.messageDetailLength = this.messageInfo.length
        this.channelTitleList.filter( x => parseInt(x.chatID) == this.chatIdForSendMessage)[0].unreadCount = 0
        this.contentLoadTotal = this.messageInfo.filter( x => x.messageType != 'text').length

        this.$nextTick(() => {
          this.scrollStartBottom();
        });

        var imgList = []
        var index = null

        if (item.platformID == 2) {
          this.messageInfo.forEach(e => {
            if (e.messageType == 'image') {
              imgList.push({
                messageId: e.messageId,
                message: this.getContentLine(e.message, 'image')
              })
            }
          });

          for (let i = 0; i < imgList.length; i++) {
            await imgList[i].message
            .then(result => {
              index = this.messageInfo.findIndex(x => x.messageId == imgList[i].messageId);
              this.messageInfo[index].message = result
              this.messageInfo[index].loadImage = true
            })
            .catch(error => {
              console.error('Error:', error);  // Handle any error
            });
          }
          // document.getElementById("loadImg").click();
          this.messageInfo = await this.messageInfo.reverse()
          this.messageInfo = this.messageInfo.reverse()

          this.$nextTick(() => {
            this.scrollStartBottom();
          });
        }

        if (this.platformId == 1 || this.platformId == 2) {
          this.getCustomerInfo()
        }

        if (this.shopCustomerIDFromChat != null) {
          await this.getCustomerHistory()
        }
        this.historyLoading = true
      }
    },

    setPlatformName() {
      if (this.platformId == 1) {
        this.platformName = 'Facebook'
      } else if (this.platformId == 2) {
        this.platformName = 'Line'
      } else if (this.platformId == 3) {
        this.platformName = 'Shopee'
      } else if (this.platformId == 4) {
        this.platformName = 'Lazada'
      }
    },

    async getMessageFromSocket(val) {
      var item = JSON.parse(val.data)
      if (this.channelInfo.platformConnectionID == item.ChatTitle.PlatformConnectionID || this.channelInfo.platformConnectionID == 0) {
        var title = {}
        var titleOld = this.channelTitleList.filter(x => parseInt(x.chatID) == item.ChatId)[0]

        //title
        if (item.SocketType == 'New') {
          title.chatID = item.ChatId
          title.shopID = item.ShopId
          title.platformConnectionID = item.ChatTitle.PlatformConnectionID
          title.platformShopName = titleOld.platformShopName
          title.platformShopProfilePicture = item.ChannelProfile
          title.platformID = item.ChatTitle.PlatformID
          title.platformName = titleOld.platformName
          title.platformLogo = titleOld.platformLogo
          title.platformSenderName = item.ChatTitle.PlatformSenderName
          title.platformSenderProfilePicture = item.ChatTitle.PlatformSenderProfilePicture
          title.lastMessage = item.ChatTitle.LastMessage
          title.lastMessageDate = item.CreatedDatetime
          title.isLastMessageSentByCustomer = item.ChatTitle.IsLastMessageSentByCustomer
          title.lastMessageID = item.MessageId
          title.referenceSourceID = item.ChannelSourceId
          if (item.sent_by_staffId == null) {
            title.unreadCount = item.ChatTitle.UnreadCount
          } else {
            title.unreadCount = 0
          }

          if (item.ChatId == this.chatIdForSendMessage) {
            title.unreadCount = 0
          }

          this.channelTitleList = this.channelTitleList.filter(x => parseInt(x.chatID) !== item.ChatId);
          this.channelTitleList.unshift(title)
          this.messageTitleLength = this.channelTitleList.length
        } else if (item.SocketType == 'Read') {
          var index = this.channelTitleList.findIndex(x => parseInt(x.chatID) == item.ChatId);
          if (index !== -1) {
            // Update the value of the found item
            this.channelTitleList[index].unreadCount = 0;
            this.channelTitleList[index].platformSenderName = item.ChatTitle.PlatformSenderName
            this.channelTitleList[index].platformSenderProfilePicture = item.ChatTitle.PlatformSenderProfilePicture
            this.channelTitleList[index].lastMessage = item.ChatTitle.LastMessage
            this.channelTitleList[index].lastMessageDate = item.ChatTitle.sent_time
            this.channelTitleList[index].isLastMessageSentByCustomer = item.ChatTitle.IsLastMessageSentByCustomer
          }
        }

        if (item.ChatId == this.chatIdForSendMessage) {

          var imageMessage = ''
          if (item.MessageType == 'image') {
            if (this.platformId == 2) {
              imageMessage = await this.getContentLine(item.Message, 'image')
            } else {
              imageMessage = item.Message
            }
          }

          var pushM = {
            "is_admin_read": item.sent_by_staffId == null ? false : true,
            "is_customer_read": true,
            "loadImage": true,
            "messageType": item.MessageType,
            "message": item.MessageType == 'image' ? imageMessage : item.Message,
            "messageId": item.MessageId,
            "message_received_timestamp": item.CreatedDatetime,
            "message_sent_timestamp": item.CreatedDatetime,
            "sent_by_admin": item.sent_by_staffId == null ? false : true,
            "sent_by_customer": item.sent_by_staffId == null ? true : false,
            "sent_by_name": item.SendBy,
            "sent_by_staffId": item.sent_by_staffId,
            "sent_by_staffName": item.sent_by_staffName,
            "sent_time": item.CreatedDatetime,
            "read_by_staffId": this.staffShopChat.id,
            "read_by_staffname": this.staffShopChat.name,
            "reply_token": ""
          }
          this.messageInfo.push(pushM)
          this.messageDetailLength = this.messageInfo.length

          this.$nextTick(() => {
            this.scrollStartBottom();
          });

          let response = await axios.post(
            expressService_dotnet + "CoreMessage/mark-read",
            {
              shopId: this.shopID,
              chatId: this.chatIdForSendMessage,
              staffShopId: this.staffID,
              messageId: item.MessageId
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token_api,
              },
            }
          );

        }
      }
    },

    async sendMessageToPlatform() {
      var message = this.messageForSendMessage

      //message
      var pushM = {
        "is_admin_read": true,
        "is_customer_read": false,
        "messageType": "text",
        "message": this.messageForSendMessage,
        "messageId": "",
        "message_received_timestamp": formatDatetime_new(new Date().toISOString()),
        "message_sent_timestamp": formatDatetime_new(new Date().toISOString()),
        "sent_by_admin": true,
        "sent_by_customer": false,
        "sent_by_name": this.staffShopChat.name,
        "sent_by_staffId": this.staffShopChat.id,
        "sent_by_staffName": this.staffShopChat.name,
        "sent_time": formatDatetime_new(new Date().toISOString()),
        "read_by_staffId": "",
        "read_by_staffname": "",
        "reply_token": ""
      }
      this.messageInfo.push(pushM)
      this.messageDetailLength = this.messageInfo.length
      this.$nextTick(() => {
        this.scrollStartBottom();
      });

      //title
      var titleOld = this.channelTitleList.filter( x => x.chatID == this.chatIdForSendMessage)[0]
      var title = {}
      title.chatID = titleOld.chatID
      title.shopID = titleOld.shopID
      title.platformConnectionID = titleOld.platformConnectionID
      title.platformShopName = titleOld.platformShopName
      title.platformShopProfilePicture = titleOld.platformShopProfilePicture
      title.platformID = titleOld.platformID
      title.platformName = titleOld.platformName
      title.platformLogo = titleOld.platformLogo
      title.platformSenderName = titleOld.platformSenderName
      title.platformSenderProfilePicture = titleOld.platformSenderProfilePicture
      title.lastMessage = this.messageForSendMessage,
      title.lastMessageDate = formatDatetime_new(new Date().toISOString())
      title.lastMessageID = ''
      title.isLastMessageSentByCustomer = false
      title.unreadCount = 0
      title.referenceSourceID = titleOld.referenceSourceID

      this.channelTitleList = this.channelTitleList.filter(x => x.chatID !== this.chatIdForSendMessage);
      this.channelTitleList.unshift(title)
      this.messageTitleLength = this.channelTitleList.length

      //clear value
      this.messageForSendMessage = ''

      // line = 2, face = 1
      var keepData = []
      if (this.platformId == 1) {
        let response = await axios.post(
          expressService_dotnet + "CoreMessage/send-message-facebook",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            message: message
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );
        keepData = response.data
      } else if (this.platformId == 2) {
        let response = await axios.post(
          expressService_dotnet + "CoreMessage/send-message-line",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            messages: message,
            replyToken: this.messageInfoOfTitle.reply_token
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );
        keepData = response.data
      } else if (this.platformId == 3) {
        let response = await axios.post(
          expressService_dotnet + "CoreMessage/send-message-shopee",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            messageType: 'text',
            content: { text: message }
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );
        keepData = response.data
      } else if (this.platformId == 4) {
        let response = await axios.post(
          expressService_dotnet + "CoreMessage/send-message-lazada",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            message: message
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );
        keepData = response.data
      }

      // if (keepData.status == 'success') {}
    },

    handleScrollTitle(event) {
      const scrollTop = event.target.scrollTop;
      const clientHeight = event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      // ตรวจสอบว่าผู้ใช้เลื่อนมาถึงล่างสุดหรือยัง
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMoreItemsTitle(); // เรียกใช้ฟังก์ชันเมื่อเลื่อนมาล่างสุด
      }
    },

    async loadMoreItemsTitle() {
      if (this.messageTitleLength >= 20) {
        var reqPlatform = [{
          platformConnectionID: this.channelInfo.platformConnectionID,
          platformID: this.channelInfo.platformID
        }]

        let response = await axios.post(
          expressService_dotnet + "CoreMessage/get-chat-title-asyncs",
          {
            shopId: this.shopID,
            platform: reqPlatform,
            skip: this.messageTitleLength,
            take: 20,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );

        response.data.data.forEach(e => {
          this.channelTitleList.push(e)
        });
        this.messageTitleLength = this.channelTitleList.length
      }
    },

    handleScrollMessage(event) {
      const scrollTop = event.target.scrollTop;

      if (scrollTop == 0) {
        this.loadMoreItemsMessage();
      }
    },

    async loadMoreItemsMessage() {
      if (this.messageDetailLength >= 10) {

        let response = await axios.post(
          expressService_dotnet + "CoreMessage/get-chat-message-detail-asyncs",
          {
            shopId: this.shopID,
            chatId: this.chatIdForSendMessage,
            staffShopId: this.staffID,
            skip: this.messageDetailLength,
            take: 10,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token_api,
            },
          }
        );

        this.messageInfo.reverse()
        response.data.messages.forEach(e => {
          this.messageInfo.push(e)
        });
        this.messageInfo.reverse()
        this.messageDetailLength = this.messageInfo.length
        this.stayScroll()
      }
    },

    stayScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const previousScrollHeight = scrollContainer.scrollHeight;
      const previousScrollTop = scrollContainer.scrollTop;

      this.$nextTick(() => {
        const newScrollHeight = scrollContainer.scrollHeight;
        scrollContainer.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
      });
    },

    // scroll start bottom
    scrollStartBottom() {
      const container = this.$refs.scrollContainer;
      setTimeout(() => {
        if (container.scrollHeight > container.clientHeight) {
          container.scrollTop = container.scrollHeight;
        }
      }, 100);

    },

    onContentLoad() {
      this.contentLoadCount++;
      if (this.contentLoadCount == this.contentLoadTotal ) {
        this.$nextTick(() => {
          this.scrollStartBottom();
        });
      }
    },

    async pushOrder() {
      var rev = this.newOrderListOfChat.filter ( x =>  x.chatId !== this.chatIdForSendMessage)
      this.newOrderListOfChat = rev
      this.newOrderListOfChat.push({
        chatId: this.chatIdForSendMessage,
        orderMaster: this.orderMaster,
        orderItem: this.orderItem,
        shopBankAccount: this.shopBankAccount,
        shippingAddress: this.shippingAddress,
        oldShippingAddress: this.oldShippingAddress,
        shopAddress: this.shopAddress,
        orderPayment: this.orderPayment,
        Warehouse: this.Warehouse,
        searchStockModel: this.searchStockModel,
        data_tmp: this.data_tmp,
        productList: this.productList,
        select_discountType: this.select_discountType,
        select_vatType: this.select_vatType,
        rd_bank: this.rd_bank,
        select_bank_exist: this.select_bank_exist,
        select_bank: this.select_bank,
        dialog_transfer: this.dialog_transfer,
        rdGroup_transferBankAccount: this.rdGroup_transferBankAccount,
        input_transferFileUpload: this.input_transferFileUpload,
        menu_transfer_date : this.menu_transfer_date,
        select_transferDate: this.select_transferDate,
        select_transferHour: this.select_transferHour,
        select_transferMinute: this.select_transferMinute,
        input_shipping_address_api : this.input_shipping_address_api,
        dialog_shipping_address: this.dialog_shipping_address,
        rd_group_shipping_address_exist: this.rd_group_shipping_address_exist,
        shipping_address_list_search_by_phone: this.shipping_address_list_search_by_phone,
        select_shiping_postcodeMappingId: this.select_shiping_postcodeMappingId,
        ProductPrice: this.ProductPrice,
        textField_deliveryCost: this.textField_deliveryCost,
        textField_discount: this.textField_discount,
        totalVat: this.totalVat,
        totalPrice: this.totalPrice,
        totalDiscount: this.totalDiscount,
        textarea_remarkProduct: this.textarea_remarkProduct
      })
    },

    async logAddOderDefault() {
      var itemDef = this.newOrderListOfChat.filter( x => x.chatId == 0)[0]
      this.orderMaster = {
                          "shopId": null,
                          "branchId": null,
                          "createdByStaffId": this.staff_id.toString(),
                          "salemanId": parseInt(this.staff_id),
                          "shippingStatusId": 0,
                          "totalPrice": null,
                          "deliveryCost": null,
                          "orderDiscountPercent": null,
                          "orderDiscount": null,
                          "vatType": null,
                          "vatpercent": null,
                          "vatAmount": null,
                          "shopAddressId": this.shopAddressIDForDefault,
                          "shippingAddressId": null,
                          "shippingAddressInvoiceId": null,
                          "isCOD": null,
                          "codamount": null,
                          "remark": "",
                          "shopBankAccountId": null,
                          "expressCompanyId": null,
                          "branchExpressID": null,
                          "shopExpressID": null,
                          "referenceSourceId": 0,
                          "warehouseId": this.Warehouse.id,
                          "referenceNumber": null,
                          "taxID": null,
                          "ExpressUniqueID": "273_1691_11",
                          "chatTitleID": null
                        }
      this.orderItem = itemDef.orderItem
      this.shopBankAccount = {
                                "id": 0,
                                "shopId": null,
                                "bankId": null,
                                "bankName": "",
                                "accountNumber": "",
                                "accountName": "",
                                "isActive": true,
                                "isDefault": true,
                                "isDeleted": false
                              }
      this.input_account_name = ""
      this.input_account_number = ""
      this.shippingAddress = {
                                "id": 0,
                                "shopId": 0,
                                "name": "",
                                "phone": "",
                                "address": "",
                                "postcodeMappingId": 0,
                                "subDistrict": "",
                                "district": "",
                                "provinceId": 0,
                                "province": "",
                                "postcode": "",
                                "shopCustomerID": 0,
                                "customerFacebook": "",
                                "customerLine": "",
                                "customerEmail": ""
                              }
      this.oldShippingAddress = {
                                  "id": 0,
                                  "shopId": 0,
                                  "name": "",
                                  "phone": "",
                                  "address": "",
                                  "postcodeMappingId": 0,
                                  "subDistrict": "",
                                  "district": "",
                                  "provinceId": 0,
                                  "province": "",
                                  "postcode": "",
                                  "shopCustomerID": 0,
                                  "customerFacebook": "",
                                  "customerLine": "",
                                  "customerEmail": ""
                                }
      this.shopAddress = itemDef.shopAddress
      this.orderPayment = null
      this.document_data = itemDef.document_data
      this.Warehouse = itemDef.Warehouse
      this.searchStockModel = itemDef.searchStockModel
      this.data_tmp = itemDef.data_tmp
      this.productList = []
      this.select_discountType = itemDef.select_discountType
      this.select_vatType = itemDef.select_vatType
      this.rd_bank = itemDef.rd_bank
      this.select_bank_exist = itemDef.select_bank_exist
      this.select_bank = itemDef.select_bank
      this.dialog_transfer = itemDef.dialog_transfer
      this.rdGroup_transferBankAccount = itemDef.rdGroup_transferBankAccount
      this.input_transferFileUpload = itemDef.input_transferFileUpload
      this.menu_transfer_date = itemDef.menu_transfer_date
      this.select_transferDate = itemDef.select_transferDate
      this.select_transferHour = itemDef.select_transferHour
      this.select_transferMinute = itemDef.select_transferMinute
      this.input_shipping_address_api = itemDef.input_shipping_address_api
      this.dialog_shipping_address = itemDef.dialog_shipping_address
      this.rd_group_shipping_address_exist = itemDef.rd_group_shipping_address_exist
      this.shipping_address_list_search_by_phone = itemDef.shipping_address_list_search_by_phone
      this.select_shiping_postcodeMappingId = itemDef.select_shiping_postcodeMappingId
      this.ProductPrice = itemDef.ProductPrice
      this.textField_deliveryCost = itemDef.textField_deliveryCost
      this.textField_discount = itemDef.textField_discount
      this.totalVat = itemDef.totalVat
      this.totalPrice = itemDef.totalPrice
      this.totalDiscount = itemDef.totalDiscount
      this.textarea_remarkProduct = itemDef.textarea_remarkProduct
    },

    async logAddOder() {
      var item = this.newOrderListOfChat.filter( x => x.chatId == this.chatIdForSendMessage)
      if (item.length == 0) {
        await this.logAddOderDefault()
      } else {
        this.orderMaster = item[0].orderMaster
        this.orderItem = item[0].orderItem
        this.shopBankAccount = item[0].shopBankAccount
        this.shippingAddress = item[0].shippingAddress
        this.oldShippingAddress = item[0].oldShippingAddress
        this.shopAddress = item[0].shopAddress
        this.orderPayment = item[0].orderPayment
        this.Warehouse = item[0].Warehouse
        this.searchStockModel = item[0].searchStockModel
        this.data_tmp = item[0].data_tmp
        this.productList = item[0].productList
        this.select_discountType = item[0].select_discountType
        this.select_vatType = item[0].select_vatType
        this.rd_bank = item[0].rd_bank
        this.select_bank_exist = item[0].select_bank_exist
        this.select_bank = item[0].select_bank
        this.dialog_transfer = item[0].dialog_transfer
        this.rdGroup_transferBankAccount = item[0].rdGroup_transferBankAccount
        this.input_transferFileUpload = item[0].input_transferFileUpload
        this.menu_transfer_date = item[0].menu_transfer_date
        this.select_transferDate = item[0].select_transferDate
        this.select_transferHour = item[0].select_transferHour
        this.select_transferMinute = item[0].select_transferMinute
        this.input_shipping_address_api = item[0].input_shipping_address_api
        this.dialog_shipping_address = item[0].dialog_shipping_address
        this.rd_group_shipping_address_exist = item[0].rd_group_shipping_address_exist
        this.shipping_address_list_search_by_phone = item[0].shipping_address_list_search_by_phone
        this.select_shiping_postcodeMappingId = item[0].select_shiping_postcodeMappingId
        this.ProductPrice = item[0].ProductPrice
        this.textField_deliveryCost = item[0].textField_deliveryCost
        this.textField_discount = item[0].textField_discount
        this.totalVat = item[0].totalVat
        this.totalPrice = item[0].totalPrice
        this.totalDiscount = item[0].totalDiscount
        this.textarea_remarkProduct = item[0].textarea_remarkProduct
      }
    },

    async GetStaffShop () {
      await axios.post(shopService_dotnet+'StaffShop/get-staffshop-only-by-staffid',
        {"id": localStorage.getItem('staff_id')},
        {headers: this.header_token }
      )
      .then((res) => {this.staffShopChat = res.data})
      .catch((error) => { console.log(error); });
    },

    async downloadImage(item) {
      if (this.platformId == 2) {
        var a = document.createElement("a"); //Create <a>
        a.href = item; //Image Base64 Goes here
        a.download = "image.jpg"; //File name Here
        a.click(); //Downloaded file
      } else {
        axios({url: item, method: 'GET', responseType: 'blob'})
        .then ((response) => {
          var fileUrl = window.URL.createObjectURL(new Blob ([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileUrl
          fileLink.setAttribute('download', 'image.jpg')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      }
    },

    viewPhoto(item) {
      this.showPhotoObj = item
      this.showPhotoChat = true
    },

    async viewVideo(item, type) {
      this.loadVideo = true
      this.showVideoObj = await this.getContentLine(item, type)
      this.showVideoChat = true
      this.loadVideo = false
    },

    async viewImage(item, type) {
      this.loadImage = true
      this.showPhotoChat = true
      this.showPhotoObj = await this.getContentLine(item, type)
      this.loadImage = false
    },

    async getContentLine (item, type) {
      let response = await axios.post(
        expressService_dotnet + "LineMessage/get-content-by-messageid?PlatformConnectionID=" + this.messageInfoOfTitle.platformConnectionID + "&messageId=" + item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token_api,
          },
        }
      );
      var base64 = ''
      if (type == 'video') {
        base64 = 'data:video/mp4;base64,'+response.data
      } else {
        base64 = 'data:image/jpg;base64,'+response.data
      }
      return base64
    },

    convertTextToUrl(text, type) {
      var urlText
      // Regular Expression สำหรับจับ URL ในข้อความ
      const urlPattern = /((https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?)/gi;

      // แทนที่ URL ด้วยลิงก์ HTML
      urlText = text.replace(urlPattern, function(url) {
        // ถ้า URL ไม่มี http/https ให้เพิ่ม
        const prefixedUrl = url.startsWith('http') ? url : `http://${url}`;
        return `<a href="${prefixedUrl}" target="_blank" class="${type == true ? 'white--text' : ''}">${url}</a>`;
      });
      return urlText
    },

    async getCustomerInfo() {
      if (this.shopCustomerIDFromChat != null) {
        if (this.shippingAddress.id == 0) {
          let response = await axios.post(
            shopService_dotnet+'Customer/get-customer-info',
            {
              'shopCustomerID': this.shopCustomerIDFromChat,
            },
            { headers: this.header_token }
          );
          if (response.data.shippingAddressList.length != 0) {
            const defaultaddress = response.data.shippingAddressList.filter((i) => i.isDefault);
            var info={};
            if(response.data.shippingAddressList.length==1){
              info = response.data.shippingAddressList[0]
            }
            else if(defaultaddress.length>0){
              info = defaultaddress[defaultaddress.length-1]
            }
            else{
              info = response.data.shippingAddressList.sort((a, b) => b.id - a.id)[0];
            }
           
            this.shippingAddress = {
                                      "id": info.id,
                                      "shopId": info.shopID,
                                      "name": info.name,
                                      "phone": info.phone,
                                      "address": info.address,
                                      "postcodeMappingId": info.postcodeMappingID,
                                      "subDistrict": info.subDistrict,
                                      "district": info.district,
                                      "provinceId": info.provinceID,
                                      "province": info.province,
                                      "postcode": info.postcode,
                                      "shopCustomerID": info.shopCustomerID,
                                      "customerFacebook": "",
                                      "customerLine": "",
                                      "customerEmail": ""
                                    }
            this.select_shiping_postcodeMappingId = info.postcodeMappingID
          }
        }
      }
    },

    async getCustomerHistory(type) {
      let response = await axios.post(
        orderService_dotnet+'Order/get-order-list',
        // 'https://order.packhai-api-88.com/Order/get-order-list',
        {
          'shopID':this.shopID,
          'shopCustomerID': this.shopCustomerIDFromChat,
          'skip': this.customerHistoryList.length,
          'take': 12,
        },
        { headers: this.header_token }
      );
      // this.customerHistoryList = response.data.orderList
      this.customerHistoryLength = response.data.orderList.length
      if (type == true) {
        response.data.orderList.forEach(e => {
          this.customerHistoryList.push(e)
        });
      } else {
        this.customerHistoryList = response.data.orderList
      }
    },

    detail_page(id) {
      window.open('order-detail?id='+id, '_blank');
    },
    async handleScrollHistory(event) {
      const scrollTop = event.target.scrollTop;
      const clientHeight = event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.customerHistoryLength >= 12 && this.scrollHistoryLoad == true) {
          this.scrollHistoryLoad = false
          await this.getCustomerHistory(true)
          this.scrollHistoryLoad = true
        }
      }
    },
    //#region
    //add order
    format_number,
    format_order_id,
    format_price,
    isNumberWNoDot,
    isNumberWithDot,
    formatMoney,
    Trim_value,
    Trim_rules,
    get_languages,
    DateNowNotNull,

    rd_is_cod_changed() {
      if (this.orderMaster.isCOD) {
        this.orderMaster.codamount =
          this.totalPrice +
          Number(this.textField_deliveryCost) -
          this.totalDiscount +
          (this.select_vatType == 3 ? this.totalVat : 0);
        this.rd_express_company_list = this.express_company_list.filter(
          (item) => {
            return item.isAllowCOD == true;
          }
        );
        this.orderPayment = null;
      } else {
        this.orderPayment = [];
        this.rd_express_company_list = this.express_company_list;

        this.input_shipping_address_phone_rules = [];
        this.input_shipping_address_name_rules = [];
        this.input_shipping_address_rules = [];
        this.select_shipping_address_mapping_rules = [];
      }
    },

    rd_bank_changed(val) {
      if (this.orderMaster.isCOD) {
        if (this.rd_bank == "bank_exist") {
          this.orderMaster.shopBankAccountId = val;
          this.shopBankAccount = null;
        } else {
          this.orderMaster.shopBankAccountId = null;
          this.shopBankAccount = null;
        }
      } else {
        this.orderMaster.shopBankAccountId = null;
        this.shopBankAccount = null;
      }
    },

    new_bank_changed() {
      this.shopBankAccount = {
        id: 0,
        shopId: null,
        bankId: null,
        bankName: "",
        accountNumber: "",
        accountName: "",
        isActive: true,
        isDefault: true,
        isDeleted: false,
      };

      var bankName = "";
      for (var i = 0; i < this.bank_list.length; i++) {
        if (this.select_bank == this.bank_list[i]["id"]) {
          bankName = this.bank_list[i]["text"];
          break;
        }
      }

      this.shopBankAccount.id = 0;
      this.shopBankAccount.shopId = parseInt(this.shop_id);
      this.shopBankAccount.accountName = this.input_account_name;
      this.shopBankAccount.bankId = this.select_bank;
      this.shopBankAccount.accountNumber = this.input_account_number;
      this.shopBankAccount.isActive = true;
      this.shopBankAccount.isDefault = true;
      this.shopBankAccount.isDeleted = false;
      this.shopBankAccount.bankName = bankName;
    },
    submit_address_invoice() {
      var ispass = true;
      if (
        this.address_invoice.phone == null ||
        this.address_invoice.phone == ""
      ) {
        this.address_invoice_validate.phone = true;
        ispass = false;
      }
      if (
        this.address_invoice.name == null ||
        this.address_invoice.name == ""
      ) {
        this.address_invoice_validate.name = true;
        ispass = false;
      }
      if (
        this.address_invoice.address == null ||
        this.address_invoice.address == ""
      ) {
        this.address_invoice_validate.address = true;
        ispass = false;
      }
      if (this.select_address_invoice_postcodeMapping == null) {
        this.address_invoice_validate.postcode = true;
        ispass = false;
      }
      if (!ispass) {
        return;
      }
      this.address_invoice.selectInvoice = true;
      this.add_address_invoice_dialog = false;
      this.dataItem_customer_invoice.forEach((element) => {
        element.selectInvoice = false;
      });
      this.dataItem_customer_invoice.push(this.address_invoice);
    },
    rd_shop_address_changed() {
      if (this.rd_group_shop_address == "exist") {
        this.orderMaster.shopAddressId = this.select_shop_address;
        // select_shop_address
        this.shopAddress = null;
      } else {
        this.orderMaster.shopAddressId = null;
        this.shopAddress = {
          id: 0,
          shopId: parseInt(this.shop_id),
          name: this.input_shop_address_name,
          phone: this.input_shop_address_phone,
          address: this.input_shop_address,
          postcodeMappingId: this.select_shop_address_mapping.Id,
          isDefault: false,
          isDeleted: false,
        };
      }
    },

    update_warehouseID() {
      this.orderMaster.warehouseId = this.Warehouse.id;
    },

    set_data_productlist_storage(val) {
      localStorage.setItem("productlist_new_order_ref", JSON.stringify(val));
    },

    get_data_productlist_storage() {
      this.productList = JSON.parse(
        localStorage.getItem("productlist_new_order_ref")
      );
    },

    async get_data_order_by_ref() {
      this.loading = true;
      await axios
        .all([
          axios.post(
            orderService_dotnet + "Order/get-order-master",
            { orderID: this.order_id_ref },
            { headers: this.header_token }
          ),
          axios.post(
            orderService_dotnet + "Order/get-order-item",
            { orderID: this.order_id_ref },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "ShopAddress/get-shop-address-list",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((res_OrderMaster, res_OrderItem, res_ShopAddress) => {
            var res = res_OrderMaster.data;
            var res_stock = res_OrderItem.data;
            var res_shop_address = res_ShopAddress.data;

            this.orderMaster.isCOD = res.isCOD;
            this.rd_is_cod_changed();
            this.orderMaster.salemanId = res.salemanID;
            if (res.expressCompanyId != null) {
              this.orderMaster.ExpressUniqueID =
                (res.branchExpressID == null
                  ? "0"
                  : res.branchExpressID.toString()) +
                "_" +
                (res.shopExpressID == null
                  ? "0"
                  : res.shopExpressID.toString()) +
                "_" +
                (res.expressCompanyId == null
                  ? "0"
                  : res.expressCompanyId.toString());
            } else {
              this.orderMaster.ExpressUniqueID = null;
            }

            this.shopBankAccount = null;
            this.shopAddress = null;

            this.orderMaster.codamount = res.codamount;

            this.orderMaster.referenceSourceId = res.referenceSourceID;
            if (res.shopBankAccountID != null) {
              this.select_bank_exist = res.shopBankAccountID;
              this.orderMaster.shopBankAccountId = res.shopBankAccountID;
              this.shopBankAccount = null;
            } else {
              if (this.bank_exist_list.length != 0) {
                this.rd_bank = "bank_exist";

                for (var i = 0; i < this.bank_exist_list.length; i++) {
                  if (this.bank_exist_list[i].isDefault == true) {
                    this.select_bank_exist = this.bank_exist_list[i].id;
                    break;
                  }
                }

                if (this.select_bank_exist == null) {
                  this.select_bank_exist = this.bank_exist_list[0].id;
                }
                this.orderMaster.shopBankAccountId = this.select_bank_exist;
              }
            }
            this.select_shop_address = res.shopAddressID;
            this.orderMaster.shopAddressId = res.shopAddressID;
            this.shopAddressIDForDefault = res.shopAddressID;
            this.shopAddress = null;

            this.textField_deliveryCost = res.deliveryCost;
            if (res.orderDiscountPercent === null) {
              this.textField_discount = res.orderDiscount;
              this.select_discountType = "฿";
            } else {
              this.textField_discount = res.orderDiscountPercent;
              this.select_discountType = "%";
            }
            this.select_vatType = res.vatType;
            // this.productList = res_stock
            this.get_data_productlist_storage();
            this.calculateTotalStockPrice();
          })
        );
      this.loading = false;
    },

    set_data_postcodeMappingId() {
      if (this.select_shiping_postcodeMappingId != null) {
        this.shippingAddress.postcodeMappingId =
          this.select_shiping_postcodeMappingId.Id;
      }
    },
    set_data_address_invoice_postcodeMappingId() {
      if (this.select_address_invoice_postcodeMapping != null) {
        this.address_invoice.postcodeMappingId =
          this.select_address_invoice_postcodeMapping.Id;
        this.address_invoice.postcode =
          this.select_address_invoice_postcodeMapping.Postcode;
        this.address_invoice.province =
          this.select_address_invoice_postcodeMapping.Province;
        this.address_invoice.district =
          this.select_address_invoice_postcodeMapping.District;
        this.address_invoice.subDistrict =
          this.select_address_invoice_postcodeMapping.SubDistrict;
      }
    },

    set_stock_id_selected() {
      this.addOrderPopup.ProductID_List = [];
    },

    async searchStock() {
      this.data_tmp.StockList = [];
      this.hideNoDataSearchStock = true;
      this.searchStockLoading = true;

      let response = await axios.post(
        stockService_dotnet + "Stock/get-stock-list",
        {
          shopID: this.shop_id,
          keyword: this.$refs.searchStockModel.internalSearch,
          warehouseID: this.Warehouse.id,
          isActive: true,
          skipStockShopIDList: this.addOrderPopup.ProductID_List,
          skip: 0,
          take: 50,
          isAscending: true,
          minQuantityAvailable: null,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.data_tmp.StockList = response.data.stockList;

        this.hideNoDataSearchStock = false;
      } else {
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
      this.searchStockLoading = false;
    },

    async unitPriceInput(item) {
      if (item.salePrice == "") {
        item.salePrice = 0;

      } else {
        item.salePrice = Number(item.salePrice).toFixed(2);
        this.calculateDiscountPercent(item);
        this.calculateTotalStockPrice();
      }
    },

    async quantityInput(item) {
      this.quantityInput_error = null;
      this.quantityInput_status = false;

      var quantity = item.quantityInput;
      if (
        quantity == "" ||
        quantity == null ||
        quantity == 0 ||
        quantity == "0"
      ) {

        item.VueComponentFocus.quantityInput();
      } else {
        this.calculateDiscountPercent(item);
        this.calculateTotalStockPrice();
      }
    },

    discountPercent(item) {
      if (item.discountPercent != "") {
        var discount =
          (Number(item.salePrice) *
            Number(item.quantityInput) *
            Number(item.discountPercent)) /
          100;
        item.discount = discount.toFixed(2);
      } else {
        item.discount = null;
      }
    },

    removeProduct(item) {
      var index = this.productList.indexOf(item);
      this.productList.splice(index, 1);
      this.data_tmp.StockList = [];
      this.calculateTotalStockPrice();
    },

    calculateDiscountPercent(item) {
      var discountPercent = Number(item.discountPercent);
      if (!Number.isNaN(discountPercent) && discountPercent != 0) {
        var price = Number(item.salePrice);
        var quantity = Number(item.quantityInput);
        item.discount = Number(
          (price * quantity * discountPercent) / 100
        ).toFixed(2);
      } else {
        item.discountPercent = null;
        item.discount = null;
      }
    },

    calculateTotalStockPrice() {
      this.totalPrice = 0;
      var price = 0;
      var quantity = 0;
      var discountPercent = 0;
      var discount = 0;
      for (var i = 0; i < this.productList.length; i++) {
        price = Number(this.productList[i].salePrice);
        quantity = Number(this.productList[i].quantityInput);
        if (quantity > 0 && price >= 0) {
          discount = Number.isNaN(Number(this.productList[i].discount))
            ? 0
            : Number(this.productList[i].discount);
          this.productList[i]["itemPrice"] = price * quantity - discount;
          this.totalPrice += price * quantity - discount;
        }
      }
      this.ProductPrice = this.totalPrice;
      //calculateDiscountOrder
      if (this.select_discountType == "฿") {
        this.orderMaster.orderDiscountPercent = null;
        this.totalDiscount = Number(this.textField_discount);
      } else {
        this.orderMaster.orderDiscountPercent = Number(this.textField_discount);
        this.totalDiscount =
          ((this.totalPrice + Number(this.textField_deliveryCost)) *
            Number(this.textField_discount)) /
          100;
      }
      //calculateVAT
      this.totalVat = 0;
      this.orderMaster.vatpercent = 0;
      if (this.select_vatType == 2) {
        this.totalVat =
          ((this.totalPrice +
            Number(this.textField_deliveryCost) -
            this.totalDiscount) *
            7) /
          107;
        this.totalVat = Number(this.totalVat).toFixed(2);
        this.orderMaster.vatpercent = 7;
      } else if (this.select_vatType == 3) {
        this.totalVat =
          ((this.totalPrice +
            Number(this.textField_deliveryCost) -
            this.totalDiscount) *
            7) /
          100;
        this.orderMaster.vatpercent = 7;
        this.totalVat = Number(this.totalVat.toFixed(2));
      }
      // cod เปลี่ยนตาม ยอดรวม

      this.orderMaster.codamount =
        this.totalPrice +
        Number(this.textField_deliveryCost) -
        this.totalDiscount +
        (this.select_vatType == 3 ? this.totalVat : 0);

      // orderMasterId
      this.orderMaster.totalPrice = this.totalPrice;
      this.orderMaster.deliveryCost = Number(this.textField_deliveryCost);
      this.orderMaster.orderDiscount = this.totalDiscount;
      this.orderMaster.vatType = this.select_vatType;
      this.orderMaster.vatAmount = this.totalVat;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    bt_addTransfer() {
      (this.select_transferDate = new Date().toISOString().substr(0, 10)),
        (this.select_transferHour = new Date().toTimeString().substring(0, 2));
      this.select_transferMinute = new Date().toTimeString().substring(3, 5);
      this.dialog_transfer = true;
    },

    bt_closeTransfer() {
      this.dialog_transfer = false;
      this.$refs.dialogTransferForm[0].resetValidation();
    },

    check_type_file() {
      if (this.input_transferFileUpload !== undefined) {
        if (
          this.input_transferFileUpload.type != "image/jpeg" &&
          this.input_transferFileUpload.type != "image/png"
        ) {
          this.input_transferFileUpload_valid = true;
          this.$refs.input_transferFileUpload.focus();
          return;
        } else {
          this.input_transferFileUpload_valid = false;
        }
      }
    },

    edit_date_format_for_dotnet(val) {
      const _date = new Date(val);
      var informDatetime_tmp = new Date(
        _date.getTime() + 7 * (60 * 60 * 1000)
      ).toISOString();
      return informDatetime_tmp;
    },

    async submit_transferPayment() {
      // เช็ค type file
      if (this.input_transferFileUpload !== undefined) {
        if (
          this.input_transferFileUpload.type != "image/jpeg" &&
          this.input_transferFileUpload.type != "image/png"
        ) {
          this.input_transferFileUpload_valid = true;
          this.$refs.input_transferFileUpload.focus();
          return;
        } else {
          this.input_transferFileUpload_valid = false;
        }
      }


      if (this.$refs.dialogTransferForm[0].validate()) {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

        if (this.input_transferFileUpload != null) {
          var fileToBase64 = await toBase64(this.input_transferFileUpload);
        }

        var orderPayment_dict = {
          id: this.orderPayment.length + 1,
          orderMasterId: 0,
          bankAccountText: this.rdGroup_transferBankAccount.fullBankName,
          amount:
            this.input_transferAmount == null
              ? null
              : parseFloat(Number(this.input_transferAmount).toFixed(2)),
          photoLink:
            this.input_transferFileUpload == null ? null : fileToBase64,
          informDatetime:
            this.select_transferDate +
            " " +
            this.select_transferHour +
            ":" +
            this.select_transferMinute +
            ":00",
          approved: null,
          approvedBy: null,
          disApproved: null,
          disApprovedBy: null,
          updateDateTime: null,
        };

        this.orderPayment.push(orderPayment_dict);

        this.dialog_transfer = false;
        // set input null
        this.$refs.dialogTransferForm[0].resetValidation();
        this.rdGroup_transferBankAccount = null;
        this.input_transferFileUpload = null;
        this.input_transferAmount = null;
      }
    },

    removeSlip(index) {
      this.orderPayment.splice(index, 1);
    },

    async address_api_click(type_address) {
      const params = new URLSearchParams();
      let tempaddress = "";
      if (type_address == "shipping_address") {
        tempaddress = this.input_shipping_address_api;
      } else if (type_address == "shop_address") {
        tempaddress = this.input_shop_address_api;
      }

      let response = await axios.post(
        "https://logistic.packhai-api-88.com/Logistic/parsed-address",
        {
          address: tempaddress,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        const data = response.data;
        if (type_address == "shipping_address") {
          this.text_address_last = "";
          this.shippingAddress.phone = data.phone == null ? "" : data.phone;
          this.select_shipping_address_id_exist = null;
          this.shippingAddress.name = data.name == null ? "" : data.name;
          this.shippingAddress.address =
            data.address == null ? "" : data.address;

          if (data.postcodeMappingID != null) {
            try {
              this.shippingAddress.postcodeMappingId = data.postcodeMappingID;
              this.shippingAddress.postcode = data.postcode;
              this.select_shiping_postcodeMappingId = data.postcodeMappingID;
            } catch (e) {
              this.shippingAddress.postcodeMappingId = null;
              this.shippingAddress.postcode = null;
              this.select_shiping_postcodeMappingId = null;
            }
          }
          // validate input
          this.input_shipping_address_phone_rules = [
            (v) => !!v || "กรุณาระบุ",
            (v) =>
              v.length == 9 ||
              v.length == 10 ||
              "กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง ex: 0812345678",
          ];
          this.input_shipping_address_name_rules = [(v) => !!v || "กรุณาระบุ"];
          this.input_shipping_address_rules = [(v) => !!v || "กรุณาระบุ"];
          this.select_shipping_address_mapping_rules = [
            (v) => !!v || "กรุณาระบุ",
          ];

          this.find_shipping_address_by_phone();
        } else if (type_address == "shop_address") {
          this.input_shop_address_phone = data.phone == null ? "" : data.phone;
          this.select_shop_address_id_exist = null;
          // await this.search_shop_address_by_phone()
          this.input_shop_address_name = data.name == null ? "" : data.name;
          this.input_shop_address = data.address == null ? "" : data.address;
          this.shopAddress.name = this.input_shop_address_name;
          this.shopAddress.address = this.input_shop_address;
          this.shopAddress.phone = this.input_shop_address_phone;
          try {
            this.shopAddress.postcodeMappingId = data.postcodeMappingID;
            this.shopAddress.postcode = data.postcode;
            this.select_shop_address_mapping = data.postcodeMappingID;
          } catch (e) {
            this.shopAddress.postcodeMappingId = null;
            this.shopAddress.postcode = null;
            this.select_shop_address_mapping = null;
          }

          // validate input
          this.input_shop_address_phone_rules = [
            (v) => !!v || "กรุณาระบุ",
            (v) =>
              v.length == 9 ||
              v.length == 10 ||
              "กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง ex: 0812345678",
          ];
          this.input_shop_address_name_rules = [(v) => !!v || "กรุณาระบุ"];
          this.input_shop_address_rules = [(v) => !!v || "กรุณาระบุ"];
          this.select_shop_address_mapping_rules = [(v) => !!v || "กรุณาระบุ"];

          // this.rd_shop_address_changed()
        }
        this.$refs.form.validate();
      }
    },

    async bt_submit_add_another_product() {
      this.loading = true;
      var index_product_add = [];
      for (var i in this.all_stock_except_stock_order) {
        var item = this.all_stock_except_stock_order[i];

        if (item.InputQuantity !== undefined && item.InputQuantity != "") {
          this.table_items.push(item);
          index_product_add.push(i);
        }
      }

      this.set_quantity_calculate_total_price();
      this.set_unit_price_calculate_total_price();
      this.dialog_add_stock_order = false;
      var count = 0;

      for (i in index_product_add) {
        var index = index_product_add[i] - count;
        this.all_stock_except_stock_order.splice(index, 1);
        count += 1;
      }
      this.loading = false;
    },

    bt_select_shipping_address() {
      for (
        var i = 0;
        i < this.shipping_address_list_search_by_phone.length;
        i++
      ) {
        if (
          this.shipping_address_list_search_by_phone[i].id ==
          this.rd_group_shipping_address_exist.id
        ) {
          this.shippingAddress = this.shipping_address_list_search_by_phone[i];
          this.oldShippingAddress = JSON.parse(
            JSON.stringify(this.shipping_address_list_search_by_phone[i])
          );
          this.select_shiping_postcodeMappingId = {
            District: this.shippingAddress.subDistrict,
            SubDistrict: this.shippingAddress.district,
            Id: this.shippingAddress.postcodeMappingId,
            Province: this.shippingAddress.province,
            ProvinceId: this.shippingAddress.provinceId,
            Postcode: this.shippingAddress.postcode,
          };

          break;
        }
      }
      this.GetCustomerAddressInvoice(this.shippingAddress.shopCustomerID);

      this.rd_group_shipping_address_exist = null;
      this.latest_shipping_add_order_item = null;
      this.dialog_shipping_address = false;

      this.show_latest_order_by_this_Address(null);
    },

    bt_close_shipping_address_dialog() {
      this.oldShippingAddress = null;
      this.shippingAddress.shopCustomerID =
        this.shipping_address_list_search_by_phone[0].shopCustomerID;
      this.rd_group_shipping_address_exist = null;
      this.latest_shipping_add_order_item = null;
      this.dialog_shipping_address = false;
    },
    async show_latest_order_by_this_Address(val) {
      var idList = [];

      if (val == null) {
        if (this.shippingAddress.id != null || this.shippingAddress.id != "") {
          idList.push(this.shippingAddress.id);
        }
      } else {
        idList = val;
      }

      if (idList.length > 0) {
        var text_address_last = "";
        let response = await axios.post(
          orderService_dotnet + "Order/get-latest-order-by-ShippingAddressID",
          {
            shippingAddressIDList: idList,
            shopID: this.shop_id,
          },
          { headers: this.header_token }
        );
        if (response.data != "") {
          var res = response.data;
          this.orderMaster.shippingAddressId = res.id;
          text_address_last =
            "เบอร์นี้เคยเปิดออเดอร์ไปแล้วเลขที่ : " +
            this.format_order_id(res.id) +
            " เมื่อวันที่ : " +
            res.createdDatetime.substring(8, 10) +
            "-" +
            res.createdDatetime.substring(5, 7) +
            "-" +
            res.createdDatetime.substring(0, 4);
        } else {
          this.orderMaster.shippingAddressId = null;
        }
      }
    },

    async bt_submit_click(display_site) {
      this.loading = true;
      var validate_input_quantity = false;
      var product_selected = [];
      var data_item_product_set;
      var StatusFalseList;
      var IsTrue;
      var stringAlert;
      if (this.productList.length == 0) {
        await this.$swal({
          position: "top",
          type: "info",
          text: "กรุณาใส่จำนวนสินค้าอย่างน้อย 1 รายการ",
        });
        this.$vuetify.goTo(this.$refs.searchStockModel, { offset: 50 });
        this.$refs.searchStockModel.focus();
        this.loading = false;
        return false;
      }

      if (this.orderMaster.isCOD == null) {
        this.rd_is_cod_rule = [(v) => !!v || "กรุณาระบุ"];
      } else {
        this.rd_is_cod_rule = [];
      }

      // Validate require
      this.rd_group_express_company_rules = [(v) => !!v || "กรุณาระบุ"];
      if (this.rd_group_shop_address == "exist") {
        this.select_shop_address_rules = [(v) => !!v || "กรุณาระบุ"];
      } else {
        this.input_shop_address_phone_rules = [
          (v) => !!v || "กรุณาระบุ",
          (v) =>
            v.length == 9 ||
            v.length == 10 ||
            "กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง ex: 0812345678",
        ];
        this.input_shop_address_name_rules = [(v) => !!v || "กรุณาระบุ"];
        this.input_shop_address_rules = [(v) => !!v || "กรุณาระบุ"];
        this.select_shop_address_mapping_rules = [(v) => !!v || "กรุณาระบุ"];
      }

      this.input_shipping_address_phone_rules = [
        (v) => !!v || "กรุณาระบุ",
        (v) =>
          v.length == 9 ||
          v.length == 10 ||
          "กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง ex: 0812345678",
      ];
      this.input_shipping_address_name_rules = [(v) => !!v || "กรุณาระบุ"];
      this.input_shipping_address_rules = [(v) => !!v || "กรุณาระบุ"];
      this.select_shipping_address_mapping_rules = [(v) => !!v || "กรุณาระบุ"];

      if (this.orderMaster.isCOD) {
        //  this.input_total_price_rules = [ v => !!v && Number(v) > 0 || "กรุณาระบุ" ]
        this.input_cod_amount_rules = [
          (v) => (!!v && Number(v) > 0) || "กรุณาระบุ",
        ];

        if (this.rd_bank == "bank_exist") {
          this.select_bank_exist_rules = [(v) => !!v || "กรุณาระบุ"];
        } else if (this.rd_bank == "bank_other") {
          this.select_bank_rules = [(v) => !!v || "กรุณาระบุ"];
          this.input_account_name_rules = [(v) => !!v || "กรุณาระบุ"];
          this.input_account_number_rules = [(v) => !!v || "กรุณาระบุ"];
        }
      } else {
        this.select_bank_exist_rules = [];
        this.select_bank_rules = [];
        this.input_account_name_rules = [];
        this.input_account_number_rules = [];
        this.input_total_price_rules = [];
        this.input_cod_amount_rules = [];
      }

      let self = this;
      var IsPass = false;
      await self.$refs['form'][0].validate();
      async function myFunction() {
        IsPass = await self.$refs['form'][0].validate();
        if (IsPass == false) {
          self.loading = false;
          var abc = await document.getElementsByClassName("error--text");
          self.$vuetify.goTo(abc[0], { offset: 50 });
          return;
        }
      }
      await myFunction();
      if (IsPass) {
        var shipping_status_id = shippingStatusDict.OpenOrder;
        var delivery_cost = self.input_delivery_cost;
        var is_cod = self.orderMaster.isCOD ? true : false;
        var shop_bank_id = self.select_bank_exist;
        var shop_bank_item = null;
        var cod_amount = self.orderMaster.isCOD
          ? self.orderMaster.codamount
          : null;
        var shop_address_id = null;
        var shop_address_item = null;
        var shipping_address_id = self.select_shipping_address_id_exist;
        var shipping_address_item = {
          name:
            self.shippingAddress.name == "" ? null : self.shippingAddress.name,
          Phone:
            self.shippingAddress.phone == ""
              ? null
              : self.shippingAddress.phone,
          Address:
            self.shippingAddress.address == ""
              ? null
              : self.shippingAddress.address,
          AddressMapping:
            self.shippingAddress.postcodeMappingId == ""
              ? null
              : self.shippingAddress.postcodeMappingId,
          ChannelID:
          self.orderMaster.referenceSourceId = this.channelSourceId,
            // self.orderMaster.referenceSourceId == ""
            //   ? null
            //   : self.orderMaster.referenceSourceId,
          ChannelName:
            self.input_channel_name == "" ? null : self.input_channel_name,
          ChannelEmail:
            self.shippingAddress.customerEmail == ""
              ? null
              : self.shippingAddress.customerEmail,
        };
        var saleman_id = self.orderMaster.salemanId;

        if (self.rd_group_shop_address == "exist") {
          shop_address_id = self.select_shop_address;
          shop_address_item = null;
        } else {
          shop_address_id = self.select_shop_address_id_exist;
          shop_address_item = {
            name: self.input_shop_address_name,
            phone: self.input_shop_address_phone,
            address: self.input_shop_address,
            postcodeMappingId: self.select_shop_address_mapping,
          };
        }

        var validate_shipping_address_item = true;

        if (shipping_address_item.name != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.Phone != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.Address != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.AddressMapping != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.ChannelID != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.ChannelName != null) {
          validate_shipping_address_item = false;
        }
        if (shipping_address_item.ChannelEmail != null) {
          validate_shipping_address_item = false;
        }
        if (validate_shipping_address_item) {
          shipping_address_item = null;
        }

        if (self.orderMaster.isCOD) {
          shipping_status_id = shippingStatusDict.WaitCheck;

          if (self.rd_bank == "bank_other") {
            shop_bank_id = null;
            shop_bank_item = {
              Bank: self.select_bank,
              AccountName: self.input_account_name,
              AccountNumber: self.input_account_number,
            };
          }
        } else if (!self.orderMaster.isCOD) {
          shop_bank_id = null;
        }

        if (self.bt_submit == "add_order") {
          // set data orderMaster
          self.orderMaster.branchId = self.branch_id;
          self.orderMaster.shopId = self.shop_id;

          self.orderItem = [];

          var string_alert = "";
          var orderItem_dict = {};
          for (var i = 0; i < self.productList.length; i++) {
            if (self.productList[i].isProductSet == true) {

              orderItem_dict = {
                id: 0,
                orderMasterId: 0,
                orderItemTempId: null,
                stockShopId: 0, //0 =  คือ product set   //self.productList[i].id,
                quantity: self.productList[i].quantityInput,
                unitPrice: self.productList[i].salePrice,
                salePrice: self.productList[i].salePrice,
                discountPercent: self.productList[i].discountPercent,
                discount: self.productList[i].discount,
                isLock: true,
                productMasterID: self.productList[i].productMasterId,
                productMasterItemID: self.productList[i].productMasterItemId,
                isProductSet: true,
              };
              self.orderItem.push(orderItem_dict);
            } else {
              orderItem_dict = {
                id: 0,
                orderMasterId: 0,
                stockShopId: self.productList[i].id,
                quantity: self.productList[i].quantityInput,
                unitPrice: self.productList[i].salePrice,
                salePrice: self.productList[i].salePrice,
                discountPercent: self.productList[i].discountPercent,
                discount: self.productList[i].discount,
                isLock: true,
                productMasterID: self.productList[i].productMasterId,
                productMasterItemID: self.productList[i].productMasterItemId,
                isProductSet: false,
              };
              self.orderItem.push(orderItem_dict);
            }
          }

          if (string_alert != "") {
            self.$swal({
              position: "top",
              type: "error",
              title: "จำนวนสินค้าไม่พอ !!",
              text: string_alert,
            });
            self.loading = false;
            return;
          }

          self.loading = true;

          // set ค่่าเก็บไว้กรณีจะสร้าง order จากข้อมูลเดิม
          self.set_data_productlist_storage(self.productList);

          if (is_cod && self.CODLimit != null && self.CODLimit > 0) {
            if (
              self.totalPrice +
                Number(
                  self.textField_deliveryCost == null
                    ? 0
                    : self.textField_deliveryCost
                ) -
                self.totalDiscount +
                (self.select_vatType == 3 ? self.totalVat : 0) >
              self.CODLimit
            )
              self.$swal({
                position: "top",
                type: "error",
                title: "จำกัดยอด COD !!",
                text:
                  "ขนส่งนี้จำกัดยอด COD ไว้ที่ " +
                  self.CODLimit.toString() +
                  " บาท",
              });
            self.loading = false;
            return;
          }

          // check  referenceNumber
          if (self.orderMaster.referenceNumber != null) {
            var ref_no_check =
              await self.get_order_by_referenceNumber_for_check(
                self.orderMaster.referenceNumber,
                self.orderMaster.shopId
              );
            if (ref_no_check != null) {
              self.$swal({
                position: "top",
                type: "warning",
                title: "เลขอ้างอิง มีใช้แล้ว !!",
                text: format_order_id(ref_no_check.id) + " ที่ใช้อยู่",
              });
              self.loading = false;
              return;
            }
          }

          if (self.db_submit_check == false) {
            var ispass = false;
            var req_count = 0;
            while (!ispass) {
              try {
                if (req_count == 5) {
                  self.$swal({
                    position: "top",
                    type: "error",
                    text: "กรุณาลองใหม่อีกครั้ง !!",
                  });
                  self.loading = false;
                  self.db_submit_check = false;
                  ispass = true;
                }

                //  ยิง add filess

                if (
                  self.orderMaster.ExpressUniqueID != "" &&
                  self.orderMaster.ExpressUniqueID != null
                ) {
                  const myArray = self.orderMaster.ExpressUniqueID.split("_");
                  self.orderMaster.expressCompanyId = parseInt(myArray[2]);
                  self.orderMaster.shopExpressID = parseInt(myArray[1]);
                  self.orderMaster.branchExpressID = parseInt(myArray[0]);
                  if (self.orderMaster.branchExpressID == 0) {
                    self.orderMaster.branchExpressID = null;
                  }
                  if (self.orderMaster.shopExpressID == 0) {
                    self.orderMaster.shopExpressID = null;
                  }
                }
                this.dataItem_customer_invoice.forEach((element) => {
                  if (element.selectInvoice) {
                    self.orderMaster.shippingAddressInvoice = element;
                  }
                });
                self.orderMaster.trackingNo = this.input_trackingNo;
                self.orderMaster.chatTitleID = this.messageInfoOfTitle.chatID
                let response = await axios.post(
                  orderService_dotnet + "Order/new-order",
                  {
                    orderMaster: self.orderMaster,
                    orderItem: self.orderItem,
                    shopBankAccount: self.shopBankAccount,
                    shippingAddress: self.shippingAddress,
                    shippingAddressInvoice: self.orderMaster.shippingAddressInvoice,
                    oldShippingAddress: self.oldShippingAddress,
                    shopAddress: self.shopAddress,
                    orderPayment: self.orderPayment,
                    orderDoc: self.document_data,
                  },
                  { headers: this.header_token }
                );
                if (response.status == 200) {
                  this.select_shop_address_mapping;
                  if (response.data.status == "success") {
                    self.loading = false;
                    ispass = true;
                    self.customer_url =
                      addOrderService.split(":")[0] +
                      ":" +
                      addOrderService.split(":")[1] +
                      ":" +
                      "8888/purchase-order=" +
                      response.data.token;
                    localStorage.setItem(
                      "OrderMasterID",
                      response.data.orderID
                    );
                    localStorage.setItem("Token", response.data.token);
                    this.messageForSendMessage = await customerWebsite + 'purchase-order=' + response.data.token
                    this.sendMessageToPlatform()
                    this.logAddOderDefault()
                    this.getCustomerHistory()
                  } else {
                    if (
                      response.data.orderItemProductResponse != null &&
                      response.data.orderItemProductResponse.length > 0
                    ) {
                      self.loading = false;
                      ispass = true;
                      // ขึ้นเตือนสินค้าสต๊อกไม่พอ
                      self.dialog_not_enough_stock = true;
                      self.orderItemProductResponse = [];
                      self.dialog_massage = response.data.error_description;
                      self.orderItemProductResponse =
                        response.data.orderItemProductResponse;
                    } else if (response.data.isNetworkError == true) {
                      self.loading = false;
                      self.db_submit_check = false;
                      self.$swal({
                        toast: true,
                        timer: 1500,
                        showConfirmButton: false,
                        position: "top",
                        type: "console.error();",
                        title: response.data.error_description,
                      });
                    } else {
                      self.loading = false;
                      self.db_submit_check = false;
                      self.$swal({
                        position: "top",
                        type: "error",
                        text: response.data.error_description,
                      });
                      ispass = true;
                    }
                  }
                } else {
                  req_count += 1;
                }
              } catch (err) {
                // ปิด loading
                self.loading = false;
                self.db_submit_check = false;
                ispass = true;
                self.$swal({
                  position: "top",
                  type: "error",
                  text: err.message,
                });
              }
            }
          }
        }
      }
    },

    copy_text() {
      let copy_text = document.getElementById("customer_url");
      if (copy_text != null) {
        copy_text.setAttribute("type", "text");
        copy_text.select();
        var is_copy = document.execCommand("copy");
        copy_text.setAttribute("type", "hidden");
        var msg = is_copy ? "สำเร็จ" : "ไม่สำเร็จ";
        this.$swal({
          position: "top",
          type: "success",
          title: "Copy " + msg,
          timer: 1000,
          showConfirmButton: false,
          toast: true,
        });
      } else {
        this.$swal({
          position: "top",
          type: "error",
          text: "เกิดข้อผิดพลาด กรุณาติดต่อ Admin",
        });
      }
    },

    calculate_cod_amount() {
      if (this.input_total_price != null && this.input_total_price != "") {
        if (
          this.input_delivery_cost != null &&
          this.input_delivery_cost != ""
        ) {
          this.orderMaster.codamount = Number(
            Number(this.input_total_price) + Number(this.input_delivery_cost)
          ).toFixed(2);
        } else {
          this.orderMaster.codamount = Number(this.input_total_price).toFixed(
            2
          );
        }
      } else {
        if (
          this.input_delivery_cost != null &&
          this.input_delivery_cost != ""
        ) {
          this.orderMaster.codamount = Number(this.input_delivery_cost).toFixed(
            2
          );
        } else {
          this.orderMaster.codamount = null;
        }
      }
    },
    async find_shipping_address_by_phone() {
      if (
        this.shippingAddress.phone != "" &&
        (this.shippingAddress.phone.length == 9 ||
          this.shippingAddress.phone.length == 10)
      ) {
        this.shipping_address_list_search_by_phone =
          await this.search_shipping_address_by_phone();

        if (this.shipping_address_list_search_by_phone.length != 0) {
          // ถ้ากรอกหมดแล้ว
          if (
            this.shippingAddress.phone != "" &&
            this.shippingAddress.address != "" &&
            this.shippingAddress.name != "" &&
            this.shippingAddress.postcodeMappingId != ""
          ) {
            this.shippingAddress.shopCustomerID =
              this.shipping_address_list_search_by_phone[0].shopCustomerID;
            if (this.shipping_address_list_search_by_phone.length == 1) {
              this.oldShippingAddress =
                this.shipping_address_list_search_by_phone[0];
              this.show_latest_order_by_this_Address(null);
              this.GetCustomerAddressInvoice(
                this.shippingAddress.shopCustomerID
              );
            } else {
              // เจอมากกว่า 1 ที่อยู่
              this.dialog_shipping_address = true;

            }
          } // ถ้ากรอกไม่หมด
          else {
            if (this.shipping_address_list_search_by_phone.length == 1) {
              // เจออันเดียว
              this.oldShippingAddress =
                this.shipping_address_list_search_by_phone[0];
              this.shippingAddress =
                this.shipping_address_list_search_by_phone[0];
              this.shippingAddress.customerLine =
                this.shippingAddress.customerLine == null
                  ? ""
                  : this.shippingAddress.customerLine;
              this.shippingAddress.customerFacebook =
                this.shippingAddress.customerFacebook == null
                  ? ""
                  : this.shippingAddress.customerFacebook;
              this.shippingAddress.customerEmail =
                this.shippingAddress.customerEmail == null
                  ? ""
                  : this.shippingAddress.customerEmail;
              this.select_shiping_postcodeMappingId =
                this.postcode_mapping.filter(
                  (item) => item.Id == this.shippingAddress.postcodeMappingId
                )[0];

              this.show_latest_order_by_this_Address(null);
              this.GetCustomerAddressInvoice(
                this.shippingAddress.shopCustomerID
              );
            } //ถ้าเจอหลายอัน โชว์ dialog ให้เลือกที่อยู่
            else {
              this.dialog_shipping_address = true;
            }
          }
        } else {
          this.dataItem_customer_invoice = [];
          this.text_address_last = "";
          this.oldShippingAddress = null;
          this.shippingAddress.customerFacebook =
            this.shippingAddress.customerFacebook;
          this.shippingAddress.customerLine = this.shippingAddress.customerLine;
          this.shippingAddress.customerEmail =
            this.shippingAddress.customerEmail;
        }
      } else {
        this.dataItem_customer_invoice = [];
        this.oldShippingAddress = null;
        this.shippingAddress.customerFacebook =
          this.shippingAddress.customerFacebook;
        this.shippingAddress.customerLine = this.shippingAddress.customerLine;
        this.shippingAddress.customerEmail = this.shippingAddress.customerEmail;
      }
    },
    async GetCustomerAddressInvoice(ShopCustomerID) {
      if (ShopCustomerID != null) {
        let response = await axios.get(
          shopService_dotnet +
            "Customer/get-customer-invoice-address-by-customerid?ShopCustomerID=" +
            ShopCustomerID,
          { headers: this.header_token }
        );

        this.dataItem_customer_invoice = response.data;
        this.dataItem_customer_invoice.forEach((item) => {
          item.selectInvoice = false;
          if (item.isDefault) {
            item.selectInvoice = true;
          }
        });
      }
    },
    async load_data() {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-warehouse-list",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "Shop/get-shop-express-addorder",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "Shop/get-shop-bank-list",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "ShopAddress/get-shop-address-list",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "Shop/get-shop-staff",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "Shop/get-shop",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet + "Shop/get-shop-channel",
            { shopID: this.shop_id },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread(
            (
              res_WarehouseList,
              res_ExpressList,
              res_BankList,
              res_ShopAddressList,
              res_Staff,
              res_Shop,
              res_ChannelList
            ) => {
              // /Shop/get-warehouse-list
              this.Warehouse_List = res_WarehouseList.data;
              this.Warehouse = res_WarehouseList.data[0];

              // /Shop/get-shop-express
              this.express_company_list = res_ExpressList.data;
              this.rd_express_company_list = this.express_company_list;
              var NotHave = true;
              if (this.express_company_list.length > 0) {
                for (var e = 0; e < this.express_company_list.length; e++) {
                  if (this.express_company_list[e]["isDefault"] == true) {
                    this.orderMaster.ExpressUniqueID =
                      this.express_company_list[e]["uniqueID"].toString();
                    NotHave = false;
                    break;
                  }
                }

                if (NotHave) {
                  if (this.express_company_list.length > 1) {
                    this.orderMaster.ExpressUniqueID =
                      this.express_company_list[1].uniqueID.toString();
                  } else {
                    this.orderMaster.ExpressUniqueID =
                      this.express_company_list[0].uniqueID.toString();
                  }
                }
              }

              // /Shop/get-shop-bank-list
              this.bank_exist_list = res_BankList.data;
              this.bank_exist_list2 = res_BankList.data;
              if (this.bank_exist_list.length != 0) {
                this.rd_bank = "bank_exist";

                for (var i = 0; i < this.bank_exist_list.length; i++) {
                  if (this.bank_exist_list[i].isDefault == true) {
                    this.select_bank_exist = this.bank_exist_list[i].id;
                    break;
                  }
                }

                if (this.select_bank_exist == null) {
                  this.select_bank_exist = this.bank_exist_list[0].id;
                }
              }

              // /Shop/get-shop-address-list
              this.shop_address_list = res_ShopAddressList.data;
              if (this.shop_address_list.length != 0) {
                this.rd_group_shop_address = "exist";
                for (var i2 = 0; i2 < res_ShopAddressList.data.length; i2++) {
                  if (res_ShopAddressList.data[i2].isDefault == true) {
                    this.select_shop_address = res_ShopAddressList.data[i2].id;
                    break;
                  }
                }
              }

              if (
                this.select_shop_address == null &&
                res_ShopAddressList.data.length != 0
              ) {
                this.select_shop_address =
                  res_ShopAddressList.data[
                    res_ShopAddressList.data.length - 1
                  ].id;
              }

              // /Shop/get-shop-staff
              this.saleman_list = res_Staff.data;
              this.orderMaster.salemanId = parseInt(this.staff_id);

              this.vat_type = res_Shop.data.defaultVatType;
              this.select_vatType = res_Shop.data.defaultVatType;

              // set condetion

              //permission  ทำได้ 2 วิธี  1 consol.log  2 get จาก service staff
              this.permission_manage_bank_account = JSON.parse(
                localStorage.getItem("permission")
              )["ManageBankAccount"];

              this.channel_source_list = res_ChannelList.data;
              if (this.channel_source_list.length > 0) {
                this.orderMaster.referenceSourceId =
                  this.channel_source_list[0]["id"];
              }
              this.data_tmp = {
                StockList: [],
              };
            }
          )
        );
    },

    order_id_click(token) {
      if (addOrderService.includes("http://packhai.com")) {
        window.open("http://packhai.com:8888/purchase-order=" + token);
      } else if (addOrderService.includes("http://localhost")) {
        window.open("http://localhost:8081/purchase-order=" + token);
      } else {
        var url =
          addOrderService.split(":")[0] +
          ":" +
          addOrderService.split(":")[1] +
          ":";
        window.open(url + "8888/purchase-order=" + token);
      }
    },

    async get_data_express_cod_limit() {
      this.CODLimit = null;
      for (let index = 0; index < this.express_company_list.length; index++) {
        if (
          this.express_company_list[index].uniqueID ==
          this.orderMaster.ExpressUniqueID
        ) {
          this.CODLimit = this.express_company_list[index].codLimit;
        }
      }
    },

    async search_shipping_address_by_phone() {
      var phone = this.shippingAddress.phone;
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shipping-address-list",
        {
          phone: phone,
          shopID: this.shop_id,
        },
        { headers: this.header_token }
      );
      return response.data;
    },

    set_cod_amount_format() {
      if (this.orderMaster.codamount == "") {
        this.orderMaster.codamount = null;
      }
    },

    set_cod_amount() {
      if (this.orderMaster.codamount != "") {
        this.orderMaster.codamount = Number(
          Number(this.input_total_price) + Number(this.input_delivery_cost)
        ).toFixed(2);
      } else {
        this.orderMaster.codamount = null;
      }
    },
    bt_addAddressInvoice() {
      this.address_invoice_validate = {
        phone: false,
        name: false,
        address: false,
        postcode: false,
      };
      var id = 0;
      if (this.dataItem_customer_invoice.length == 0) {
        id = -1;
      } else {
        id = Math.min(
          ...this.dataItem_customer_invoice.map(function (item) {
            return item.id;
          })
        );
        if (id > 0) {
          id = -1;
        } else {
          id--;
        }
      }

      this.address_invoice = {
        id: id,
        name: null,
        phone: null,
        address: null,
        subDistrict: null,
        district: null,
        province: null,
        provinceId: null,
        postcodeMappingId: null,
        postcode: null,
        taxID: null,
      };

      if (this.shippingAddress.name != null) {
        this.address_invoice.name = this.shippingAddress.name;
      }
      if (this.shippingAddress.address != null) {
        this.address_invoice.address = this.shippingAddress.address;
      }
      if (this.shippingAddress.phone != null) {
        this.address_invoice.phone = this.shippingAddress.phone;
      }
      if (
        this.shippingAddress.postcodeMappingId != null &&
        this.shippingAddress.postcodeMappingId != 0
      ) {
        this.address_invoice.subDistrict = this.shippingAddress.subDistrict;
        this.address_invoice.district = this.shippingAddress.district;
        this.address_invoice.province = this.shippingAddress.province;
        this.address_invoice.provinceId = this.shippingAddress.provinceId;
        this.address_invoice.postcodeMappingId =
          this.shippingAddress.postcodeMappingId;
        this.address_invoice.postcode = this.shippingAddress.postcode;
        this.select_address_invoice_postcodeMapping =
          this.postcode_mapping.filter(
            (item) => item.Id == this.shippingAddress.postcodeMappingId
          )[0];
      }

      this.add_address_invoice_dialog = true;
    },

    set_format_input_total_price_two_decimal(val) {
      if (val != "") {
        this.input_total_price = Number(val).toFixed(2);
      } else {
        this.input_total_price = null;
      }
    },

    set_format_input_delivery_cost_two_decimal(val) {
      if (val != "") {
        this.input_delivery_cost = Number(val).toFixed(2);
      } else {
        this.input_delivery_cost = null;
      }
    },

    set_quantity_calculate_total_price() {
      var total_price = null;
      for (var i in this.table_items) {
        var quantityInput = this.table_items[i].InputQuantity;

        if (quantityInput !== undefined && quantityInput != "") {
          var PriceRangeList = this.table_items[i].PriceRangeList;
          var QuantityAvailable = this.table_items[i].QuantityAvailable;

          if (quantityInput <= 0) {
            this.table_items[i].InputQuantity = null;
          } else {
            if (Number(quantityInput) > Number(QuantityAvailable)) {
              this.table_items[i].InputQuantity = QuantityAvailable;
              quantityInput = QuantityAvailable;
            }

            if (
              this.table_items[i].InputUnitPrice === undefined ||
              this.table_items[i].InputUnitPrice == ""
            ) {
              if (PriceRangeList.length != 0) {
                for (var j in PriceRangeList) {
                  if (
                    quantityInput >= PriceRangeList[j].QuantityFrom &&
                    quantityInput <= PriceRangeList[j].QuantityTo
                  ) {
                    this.table_items[i].salePrice = PriceRangeList[j].Price;
                  } else if (
                    quantityInput >= PriceRangeList[j].QuantityFrom &&
                    PriceRangeList[j].QuantityTo == null
                  ) {
                    this.table_items[i].salePrice = PriceRangeList[j].Price;
                  }
                }
              }
            }
            var salePrice = this.table_items[i].salePrice;

            if (salePrice != null && salePrice != "") {
              total_price += quantityInput * salePrice;
            }
          }
        } else {
          this.table_items[i].InputUnitPrice = "";
          this.table_items[i].salePrice = this.table_items[i].UnitPriceInStock;
        }
      }

      this.input_total_price =
        total_price == null ? total_price : total_price.toFixed(2);
      this.calculate_cod_amount();
    },

    set_unit_price_calculate_total_price() {
      var total_price = null;
      for (var i in this.table_items) {
        var quantityInput = this.table_items[i].InputQuantity;

        if (quantityInput !== undefined && quantityInput != "") {
          var Price = this.table_items[i].salePrice;

          if (Price == "" || Price == null) {
            this.table_items[i].InputUnitPrice = "";
          } else {
            var number = Price.split(".");

            if (number[1] !== undefined) {
              if (number[1].length > 2) {
                var index = number[1].length - 2;

                this.table_items[i].salePrice = Price.slice(0, -index);
                Price = Price.slice(0, -index);
              } else {
                this.table_items[i].salePrice = Number(
                  this.table_items[i].salePrice
                ).toFixed(2);
              }
            } else {
              this.table_items[i].salePrice = Number(
                this.table_items[i].salePrice
              ).toFixed(2);
            }

            this.table_items[i].InputUnitPrice = Price;
            total_price += quantityInput * Price;
          }
        }
      }

      this.input_total_price =
        total_price == null ? total_price : total_price.toFixed(2);
      this.calculate_cod_amount();
    },

    // windowSize
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },

    async PopupsearchStock(limits, offsets) {
      if (this.searchStockModelPopup != null) {
        this.Popupsearch = this.searchStockModelPopup.trim();
        this.loading = true;
        if (this.Popupsearch.length > 0) {
          this.getSelectProduct(limits, offsets, this.Popupsearch, 1);
        } else {
          this.getSelectProduct(limits, offsets, null, 1);
        }
      }
    },
    async OpenPopup(limits, offsets) {
      this.loading = true;
      this.searchStockModelPopup = null;
      this.addOrderPopup.Select_Product = [];
      this.addOrderPopup.Data = [];
      this.addOrderPopup.ProductID_List = [];
      this.addOrderPopup.loading = false;
      this.Popupsearch = null;

      for (var i in this.productList) {
        if (
          !this.addOrderPopup.ProductID_List.includes(this.productList[i]["id"])
        ) {
          if (this.productList[i].id != null) {
            // this.addOrderPopup.ProductID_List.push(this.productList[i].id)
            this.addOrderPopup.ProductID_List.push(this.productList[i]["id"]);
          }
        }
      }
      this.page = 1;
      this.pageTemp = 1;
      this.getSelectProduct(
        limits,
        offsets,
        this.searchStockModelPopup,
        this.page
      );
    },

    async getSelectProduct(limits, offsets, searchstock, page) {
      this.loading = true;
      let response = await axios.post(
        stockService_dotnet + "Stock/get-stock-list-addorder",
        {
          shopID: this.shop_id,
          keyword: searchstock,
          warehouseID: this.Warehouse.id,
          skipStockShopIDList: this.addOrderPopup.ProductID_List,
          skip: offsets,
          take: limits,
        },
        { headers: this.header_token }
      );

      if (response.status == 200) {
        this.pageLength = Math.ceil(response.data.resultCount / this.limits);
        if (this.pageLength <= 0) {
          this.pageLength = 1;
        }
        this.page = page;
        this.pageTemp = page;

        var StockShopID_list = [];
        if (this.size == "xs" || this.size == "sm") {
          // await this.SetUp_Product()
          // var StockShopID_list = []

          for (var a in response.data.stockList) {
            response.data.stockList[a]["stockFullname"] =
              response.data.stockList[a]["stockFullName"];

            // stockFullName
            if (StockShopID_list.includes(response.data.stockList[a]["id"])) {
              response.data.stockList[a]["selectProduct"] = true;
            } else {
              response.data.stockList[a]["selectProduct"] = false;
            }
          }
        } else {
          for (var b in response.data.stockList) {
            response.data.stockList[b]["stockFullname"] =
              response.data.stockList[b]["stockFullName"];
            response.data.stockList[b]["selectProduct"] = false;
          }
        }
        this.addOrderPopup.Data = response.data.stockList;
        for (var i in this.addOrderPopup.Select_Product) {
          StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"]);
        }
        this.loading = false;
        this.addOrderPopup.Dialog = true;
      } else {
        this.loading = false;
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },

    check_data() {
      if (this.selectProduct_valid == false) {
        this.selectProduct_valid = true;
      } else {
        this.selectProduct_valid = false;
      }
    },

    ProductSelect(item, index) {
      this.addOrderPopup.Data[index]["selectProduct"] = !item["selectProduct"];
      if (item["selectProduct"] == true) {
        //นำเข้า Select_Product
        this.addOrderPopup.Select_Product.splice(0, 0, item);
      } else {
        //นำออก Select_Product
        var data = this.addOrderPopup.Select_Product;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.addOrderPopup.Select_Product.splice(i, 1);
            break;
          }
        }
      }
    },
    ProductSelectCheckbox(item, index) {
      if (item["selectProduct"]) {
        //นำเข้า Select_Product
        this.addOrderPopup.Select_Product.splice(0, 0, item);
      } else {
        //นำออก Select_Product
        var data = this.addOrderPopup.Select_Product;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.addOrderPopup.Select_Product.splice(i, 1);
            break;
          }
        }
      }
    },
    AddressInvoiceSelectCheckbox(item) {
      this.dataItem_customer_invoice.forEach((element) => {
        if (element.id == item.id) {
          //nothing
        } else {
          element.selectInvoice = false;
        }
      });
      this.dataItem_customer_invoice = this.dataItem_customer_invoice.filter(
        (item) => {
          return item;
        }
      );
    },
    SetUp_Product() {
      var StockShopID_list = [];
      for (var i in this.addOrderPopup.Select_Product) {
        StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"]);
      }
      for (var a in this.addOrderPopup.Data) {
        if (StockShopID_list.includes(this.addOrderPopup.Data[a]["id"])) {
          this.addOrderPopup.Data[a]["selectProduct"] = true;
        } else {
          this.addOrderPopup.Data[a]["selectProduct"] = false;
        }
      }
    },
    async Submit_addOrderPopup() {
      this.addOrderPopup.loading = true;
      for (var i in this.addOrderPopup.Select_Product) {
        await this.searchStockModel_push(this.addOrderPopup.Select_Product[i]);
      }
      this.addOrderPopup.loading = false;
      this.addOrderPopup.Dialog = false;
    },
    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        this.offsets = this.page * this.limits - this.limits;
        this.loading = true;
        await this.getSelectProduct(
          this.limits,
          this.offsets,
          this.Popupsearch,
          this.page
        );

        if (this.addOrderPopup.Select_Product.length > 0) {
          for (var item_1 of this.addOrderPopup.Select_Product) {
            for (var item_2 of this.addOrderPopup.Data) {
              if (item_1.id == item_2.id) {
                item_2.selectProduct = true;
                continue;
              }
            }
          }
        }

        this.pageTemp = this.page;
      }
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(photoLink) {
      window.open(photoLink);
    },
    async searchStockModel_push(value) {
      if (value != null) {
        value.itemPrice = 0;

        var IsHave = false;
        if (value.isProductSet == true || value.isProductSet == 1) {
          for (var i1 = 0; i1 < this.productList.length; i1++) {
            if (
              (this.productList[i1].isProductSet == true ||
                this.productList[i1].isProductSet == 1) &&
              this.productList[i1].productMasterId == value.productMasterId
            ) {
              IsHave = true;
              break;
            }
          }

          if (IsHave == false) {
            await this.productList.push(value);
          }
        } else {
          await this.productList.push(value);
        }
        if (IsHave == false) {
          var index = this.productList.indexOf(value);
          this.productList[index].discountPercent = null;

          this.productList[index].discount = null;
          this.productList[index].VueComponentFocus = {
            salePrice: this.$refs.untiPriceInput.focus,
            quantityInput: this.$refs.quantityInput.focus,
          };

          this.searchStockModel = null;
          this.data_tmp.StockList = [];
          this.hideNoDataSearchStock = true;

          if (this.productList[index].salePrice == null) {
            this.productList[index].salePrice = 0.0;
          }
          this.productList[index].quantityInput = 1;
          this.calculateTotalStockPrice();

          if (this.productList.length > 0) {
            this.addOrderPopup.ProductID_List = [];
            for (var i = 0; i < this.productList.length; i++) {
              if (this.productList[i].id != null) {
                this.addOrderPopup.ProductID_List.push(this.productList[i].id);
              }
            }
          }
        }
      }
    },

    async check_stock_item_product_set(productMasterID, quantity) {
      // this.loading = true
      let response = await axios.post(
        stockService_dotnet + "Stock/check-stock-avalible-in-product-set",
        {
          productMasterID: productMasterID,
          quantity: quantity,
        },
        { headers: this.header_token }
      );
      // this.loading = false
      return response.data;
    },

    async get_order_by_referenceNumber_for_check(referenceNumber, shopID) {
      let response = await axios.post(
        orderService_dotnet + "Order/get-order-by-referrance-no",
        {
          shopID: shopID,
          referranceNo: referenceNumber.trim(),
        },
        { headers: this.header_token }
      );

      if (response.data == null || response.data == "") {
        return null;
      } else {
        return response.data;
      }
    },

    async for_check_postcode_address(text) {
      const cleanText = this.removePrefix(text);
      const wordlist = cleanText.split(" ").filter((word) => word.length >= 3);
      var res_list = [];
      if (wordlist.length > 4) {
        var length_check = wordlist.length;
        if (wordlist[length_check - 1].length != 5) {
          wordlist.pop();
        }
        var length_tmp = wordlist.length;
        res_list.push(wordlist[length_tmp - 1]);
        res_list.push(wordlist[length_tmp - 2]);
        res_list.push(wordlist[length_tmp - 3]);
        res_list.push(wordlist[length_tmp - 4]);
        return res_list;
      } else {
        return res_list;
      }
    },

    removePrefix(text) {
      const prefixPattern = /(เขต|แขวง|จังหวัด|อำเภอ|ตำบล|อ\.|ต\.|จ\.)/g;
      let string = text.replace(/\s+/g, " "); //มี space เยอะก็ลดเหลือ 1 พอ
      string = string.replace(prefixPattern, "");
      return string;
    },

    bt_addDoc() {
      this.dialog_doc = true;
    },

    async removeDoc(index) {
      await this.delete_file_dot_net(this.document_data[index].fileUrl);
      this.document_data.splice(index, 1);
    },

    bt_closeDoc() {
      this.dialog_doc = false;
      this.$refs.dialogDoc.resetValidation();
    },

    async submit_docFile() {
      if (this.input_doc_select == null) {
        this.$refs.input_doc_select.focus();
        return;
      }
      if (this.input_docFileUpload == null) {
        this.$refs.input_docFileUpload.focus();
        return;
      }

      var url_file = await this.upload_file_py(this.input_docFileUpload);

      var document_dict = {
        id: this.document_data.length + 1,
        orderMasterID: 0,
        documentType: this.input_doc_select,
        fileUrl: url_file,
        extension: this.input_docFileUpload.type,
        createdDatetime: null,
      };

      this.document_data.push(document_dict);
      this.dialog_doc = false;
      this.input_docFileUpload = null;
      this.input_doc_select = null;
    },

    blobToUint8Array(b) {
      var uri = URL.createObjectURL(b),
        xhr = new XMLHttpRequest(),
        i,
        ui8;

      xhr.open("GET", uri, false);
      xhr.send();

      URL.revokeObjectURL(uri);

      ui8 = new Uint8Array(xhr.response.length);

      for (i = 0; i < xhr.response.length; ++i) {
        ui8[i] = xhr.response.charCodeAt(i);
      }

      return ui8;
    },

    async get_postcode_mapping() {
      var postcode_mapping = JSON.parse(
        localStorage.getItem("postcodeMapping_dotnet")
      );
      if (postcode_mapping === "undefined") {
        postcode_mapping = null;
      } else if (postcode_mapping == "null") {
        postcode_mapping = null;
      } else if (postcode_mapping == null) {
        postcode_mapping = null;
      } else if (postcode_mapping == "") {
        postcode_mapping = null;
      }
      if (postcode_mapping != null) {
        this.postcode_mapping = postcode_mapping;
      } else {
        await axios
          .post(
            generalService_dotnet + "General/get-postcodemapping",
            {},
            { headers: this.header_token }
          )
          .then((response) => {
            localStorage.setItem(
              "postcodeMapping_dotnet",
              JSON.stringify(response.data)
            );
            this.postcode_mapping = response.data;
          });
      }
    },

    async upload_file_py(file_data) {
      var from_data = new FormData();
      from_data.append("bucket", "Doc_file");
      from_data.append("file", file_data);
      // อัพโหลดภาพ
      let UploadFileResponse = await axios.post(
        generalService_dotnet + "General/upload-file-with-bucket",
        from_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token_api,
          },
        }
      );
      if (
        UploadFileResponse.status == 200 &&
        UploadFileResponse.data.status == "success"
      ) {
        return UploadFileResponse.data.file_path;
      } else {
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
        });
      }
    },

    async delete_file_dot_net(url_file) {
      let response = await axios.post(
        generalService_dotnet + "General/delete-file",
        {
          url: url_file,
        },
        { headers: this.header_token }
      );
    },
    async get_authorize_staffshop() {
      if (localStorage.getItem("310") == 2) {
        this.authorize = true;
      }
    },
    //add order
    //#endregion
  },
};
</script>
<style scoped>
.v-tab{
  padding: 0px !important;
}
 
.border {
  border-right: 1px solid #dcdee2;
}

hr.history1 {
  border-top: 1px solid #dcdee2;
}

.border-head {
  border-bottom: 1px solid #dcdee2;
}
>>>.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
>>>.v-select__selection--comma {
  white-space: normal;
}
</style>

<style>
.scroll-container {
  /* height: 500px; */
  overflow-y: auto; /* ทำให้สามารถเลื่อนหน้าเว็บได้ */
}

.scroll-container-col3 {
  /* height: 600px; */
  overflow-y: auto; /* ทำให้สามารถเลื่อนหน้าเว็บได้ */
}

.container-chat {
  overflow:auto;
}

.left-content {
  float:left;
}

.right-content {
  float:right;
}

.platform-head {
  font-size: 13px;
  color: grey;
}

.time-send {
  font-size: 10px;
  color: grey;
}

.title-select {
  width: 30px;
  border-radius: 100px !important;
}

.container-play { 
  position: relative;
}

.center-play {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
